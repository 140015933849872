import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const InstalacoesEletricas = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                   
        




                    <div className="post-items">
                   <img src="img/portfolio/instalacao.png" alt="" className="img-item" />
                       <div className="text-item">
                        <p><strong>O que é Instalação Elétrica?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">Instalação elétrica é o nome dado ao conjunto de componentes que compõem o sistema elétrico de um ambiente. Ela é responsável por distribuir energia elétrica de forma segura e eficiente, garantindo o funcionamento de aparelhos e equipamentos elétricos.</p>
                        <p className="text-justify">Uma instalação elétrica bem feita é fundamental para evitar acidentes, como curtos-circuitos, choques elétricos e incêndios. Além disso, ela garante o bom funcionamento dos equipamentos e a economia de energia.</p>
                        <p className="text-justify">Por isso, é importante contar com profissionais qualificados para realizar a instalação elétrica de sua residência, comércio ou indústria. Eles irão avaliar as necessidades do ambiente e dimensionar os componentes elétricos de forma adequada, garantindo a segurança e o bom funcionamento do sistema.</p>
                        
                        <h1>Tipos de Instalação Elétrica</h1>
                        <br />
                        <br />
                        <p className="text-justify">Existem diferentes tipos de instalação elétrica, que variam de acordo com o tipo de ambiente e a carga elétrica a ser suportada. Os principais tipos são:</p>
                        <p className="text-justify"><strong>Instalação Elétrica Residencial:</strong> é a instalação elétrica de uma residência, que deve ser dimensionada de acordo com o número de cômodos, aparelhos e equipamentos elétricos. Ela deve garantir a segurança dos moradores e o bom funcionamento dos equipamentos.</p>
                        <p className="text-justify"><strong>Instalação Elétrica Comercial:</strong> é a instalação elétrica de um estabelecimento comercial, como lojas, escritórios e restaurantes. Ela deve ser dimensionada de acordo com a carga elétrica do ambiente e garantir o funcionamento dos equipamentos e a segurança dos clientes e funcionários.</p>
                        <p className="text-justify"><strong>Instalação Elétrica Industrial:</strong> é a instalação elétrica de uma indústria, que deve ser dimensionada de acordo com a carga elétrica dos equipamentos e máquinas. Ela deve garantir o funcionamento da linha de produção e a segurança dos trabalhadores.</p>
                        <p className="text-justify"><strong>Instalação Elétrica Predial:</strong> é a instalação elétrica de um prédio, que deve ser dimensionada de acordo com o número de apartamentos e áreas comuns. Ela deve garantir o funcionamento dos elevadores, bombas d'água e sistemas de segurança.</p>
                        <p className="text-justify"><strong>Instalação Elétrica Rural:</strong> é a instalação elétrica de uma propriedade rural, que deve ser dimensionada de acordo com a carga elétrica dos equipamentos agrícolas. Ela deve garantir o funcionamento dos sistemas de irrigação, ordenha e armazenamento.</p>
                        <p className="text-justify"><strong>Instalação Elétrica Temporária:</strong> é a instalação elétrica de um evento temporário, como shows, feiras e festas. Ela deve ser dimensionada de acordo com a carga elétrica dos equipamentos e garantir a segurança dos participantes.</p>

                        <h1>Normas Técnicas para Instalação Elétrica</h1>
                        <br />
                        <br />
                        <p className="text-justify">As instalações elétricas devem ser realizadas de acordo com as normas técnicas vigentes, que estabelecem os requisitos mínimos de segurança e qualidade. As principais normas são:</p>
                        <p className="text-justify"><strong>ABNT NBR 5410:</strong> estabelece as condições mínimas necessárias para o projeto, execução e manutenção das instalações elétricas de baixa tensão.</p>
                        <p className="text-justify"><strong>ABNT NBR 5419:</strong> estabelece as condições mínimas necessárias para o projeto, execução e manutenção das instalações elétricas de proteção contra descargas atmosféricas.</p>
                        <p className="text-justify"><strong>ABNT NBR 14039:</strong> estabelece as condições mínimas necessárias para o projeto, execução e manutenção das instalações elétricas de média tensão.</p>
                        <p className="text-justify"><strong>ABNT NBR 5413:</strong> estabelece as condições mínimas necessárias para a iluminação de interiores.</p>
                        <p className="text-justify"><strong>ABNT NBR 13570:</strong> estabelece as condições mínimas necessárias para a iluminação de vias públicas.</p>
                        <p className="text-justify"><strong>ABNT NBR 5418:</strong> estabelece as condições mínimas necessárias para a proteção contra choques elétricos em instalações elétricas.</p>
                        <p className="text-justify"><strong>ABNT NBR 5411:</strong> estabelece as condições mínimas necessárias para a proteção contra incêndios em instalações elétricas.</p>

                        <h1>Por que contratar a Fortesol?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A Fortesol é uma empresa especializada em instalações elétricas, que conta com uma equipe de profissionais qualificados e experientes. Nossos técnicos estão preparados para realizar instalações elétricas de baixa, média e alta tensão, garantindo a segurança e o bom funcionamento do sistema.</p>
                        <p className="text-justify">Além disso, utilizamos materiais de qualidade e seguimos as normas técnicas vigentes, garantindo a eficiência e durabilidade das instalações elétricas. Estamos sempre atualizados com as novas tecnologias e tendências do mercado, para oferecer as melhores soluções aos nossos clientes.</p>
                        <p className="text-justify">Se você está precisando de uma instalação elétrica segura e eficiente, entre em contato conosco. Estamos prontos para atender as suas necessidades e garantir a sua satisfação.</p>
                        
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





        <Contact />

        </>
    );
};

