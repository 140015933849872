import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Image } from "../components/image";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const AnaliseEnergia = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                
              

                    <div className="post-items">
                  <img src="img/portfolio/analise-energia.png" className="img-item" alt="" />
                      
                      <div className="text-item">
                        <p><strong>O que é laudo de Qualidade de Energia?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O laudo de qualidade de energia é um documento técnico que tem como objetivo avaliar a qualidade da energia elétrica fornecida por uma concessionária de energia elétrica ou por um sistema de geração de energia. O laudo de qualidade de energia é um documento técnico que tem como objetivo avaliar a qualidade da energia elétrica fornecida por uma concessionária de energia elétrica ou por um sistema de geração de energia.</p>
                        
                        <h1>Por que fazer um laudo de Qualidade de Energia?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A qualidade da energia elétrica é um fator importante para o bom funcionamento dos equipamentos elétricos e eletrônicos de uma instalação. Uma energia de má qualidade pode causar danos aos equipamentos, reduzir a vida útil dos equipamentos e aumentar o consumo de energia elétrica. Além disso, uma energia de má qualidade pode causar problemas de segurança, como curtos-circuitos e incêndios.</p>
                        <p className="text-justify">O laudo de qualidade de energia é importante para identificar problemas na qualidade da energia elétrica fornecida por uma concessionária de energia elétrica ou por um sistema de geração de energia. O laudo de qualidade de energia pode identificar problemas como:</p>
                        <ul>
                            <li>Variações de tensão;</li>
                            <li>Variações de frequência;</li>
                            <li>Harmonicos;</li>
                            <li>Distorções harmônicas;</li>
                            <li>Desbalanços de tensão;</li>
                            <li>Desbalanços de corrente;</li>
                            <li>Interrupções de energia;</li>
                            <li>Flutuações de tensão;</li>
                            <li>Flutuações de frequência;</li>
                            <li>Transitórios;</li>
                            <li>Surto de tensão;</li>
                            <li>Surto de corrente;</li>
                        </ul>
                        <h1>Como é feito um laudo de Qualidade de Energia?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O laudo de qualidade de energia é feito por um engenheiro eletricista ou por uma empresa especializada em qualidade de energia. O engenheiro eletricista ou a empresa especializada em qualidade de energia realiza uma série de medições e análises para avaliar a qualidade da energia elétrica fornecida por uma concessionária de energia elétrica ou por um sistema de geração de energia.</p>
                        <h1>Onde fazer um laudo de Qualidade de Energia?</h1>
                        <br />
                        <br />
                        <p className="text-justify"> A Fortesol Energia é uma empresa especializada em qualidade de energia. A Fortesol Energia realiza laudos de qualidade de energia em todo o estado do Ceará. Entre em contato conosco e solicite um orçamento para o seu laudo de qualidade de energia.</p>
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                        
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>
                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





           <Contact />

        </>
    );
};

