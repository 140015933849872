import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Subestacao = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                  
           



                    <div className="post-items">
                    <img src="img/portfolio/subestacao.png" alt="" className="img-item" />

                  <div className="text-item">
                        <p><strong>O que é Projeto de Subestação?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O projeto de subestação é um conjunto de atividades que visam a elaboração de um projeto elétrico para a construção de uma subestação de energia. A subestação é um local onde a energia elétrica é transformada e distribuída para os consumidores finais. O projeto de subestação é fundamental para garantir a segurança e a eficiência do sistema elétrico, bem como para atender às normas técnicas e regulamentações vigentes.</p>
                        <p className="text-justify">O projeto de subestação é elaborado por profissionais especializados na área de engenharia elétrica, que possuem conhecimento técnico e experiência na elaboração de projetos elétricos. O projeto de subestação deve levar em consideração diversos aspectos, como a capacidade de carga, a tensão de operação, a localização da subestação, entre outros fatores.</p>
                        
                        <h1>Por que fazer um Projeto de Subestação?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O projeto de subestação é fundamental para garantir a segurança e a eficiência do sistema elétrico. A subestação é responsável por transformar a energia elétrica de alta tensão, proveniente das usinas geradoras, em energia de baixa tensão, que é distribuída para os consumidores finais. O projeto de subestação é essencial para garantir que a energia elétrica seja distribuída de forma segura e eficiente, evitando falhas no sistema elétrico e garantindo o fornecimento contínuo de energia para os consumidores.</p>
                        <p className="text-justify">Além disso, o projeto de subestação é importante para atender às normas técnicas e regulamentações vigentes, que estabelecem os requisitos mínimos para a construção e operação de subestações de energia. O projeto de subestação deve ser elaborado por profissionais qualificados, que possuam conhecimento técnico e experiência na área de engenharia elétrica, para garantir a segurança e a eficiência do sistema elétrico.</p>

                        <h1>Quais são os benefícios do Projeto de Subestação?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O projeto de subestação traz diversos benefícios para os consumidores e para o sistema elétrico como um todo. Alguns dos principais benefícios do projeto de subestação são:</p>
                        <p className="text-justify">- Segurança: o projeto de subestação é fundamental para garantir a segurança do sistema elétrico, evitando falhas e acidentes que possam comprometer a integridade das instalações elétricas e a segurança dos trabalhadores e dos consumidores.</p>
                        <p className="text-justify">- Eficiência: o projeto de subestação é importante para garantir a eficiência do sistema elétrico, otimizando o fornecimento de energia e reduzindo as perdas de energia durante a distribuição.</p>
                        <p className="text-justify">- Confiabilidade: o projeto de subestação é essencial para garantir a confiabilidade do sistema elétrico, assegurando o fornecimento contínuo de energia para os consumidores, sem interrupções ou falhas no sistema elétrico.</p>
                        <p className="text-justify">- Atendimento às normas técnicas: o projeto de subestação deve atender às normas técnicas e regulamentações vigentes, que estabelecem os requisitos mínimos para a construção e operação de subestações de energia, garantindo a segurança e a eficiência do sistema elétrico.</p>

                        <h1>Como contratar um Projeto de Subestação?</h1>
                        <br />
                        <br />
                        <p className="text-justify">Para contratar um projeto de subestação, é importante buscar por uma empresa especializada na área de engenharia elétrica, que possua experiência na elaboração de projetos elétricos e que atenda às normas técnicas e regulamentações vigentes. A empresa contratada deve possuir profissionais qualificados, que possuam conhecimento técnico e experiência na área de engenharia elétrica, para garantir a segurança e a eficiência do sistema elétrico.</p>

                        <p className="text-justify">A Fortesol Energia é uma empresa especializada na área de engenharia elétrica, que oferece serviços de projeto de subestação para os mais diversos segmentos do mercado. Contamos com uma equipe de profissionais qualificados, que possuem conhecimento técnico e experiência na elaboração de projetos elétricos, para garantir a segurança e a eficiência do sistema elétrico.</p>
                        
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>




<Contact />

        </>
    );
};

