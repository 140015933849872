import React from "react";
import { Link } from "react-router-dom";
export const Header = (props) => {
  return (
    <header id="header ">
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <Link  to="/">
            <img src="img/logosemfundo.png" alt="" height="55px" />
          </Link>{" "}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#header" className="page-scroll serv">
                Home
              </a>
            </li>
            
            <li>
              <Link to="/services"  className="page-scroll ">
                Serviços
              </Link>
            </li>
                  {/*<li>
              <Link to="/#testimonials" className="page-scroll">
                Clientes
              </Link>
  </li>*/}
            <li>
              <a href="/#about" className="page-scroll">
                Quem somos
              </a>
            </li>
            <li>
              <Link to="/blog" className="page-scroll">
                Blog
              </Link>
            </li>
            <li>
              <a href="/#contact" className="page-scroll">
                Contato
              </a>
            </li>
            <li>
              <Link 
               to="/budget" className="page-scroll btn-orcamento">
                Orçamento
              </Link>
            </li>
            <li>
                    <Link
                    target="_blank" 
                    to="https://www.facebook.com/fortesol.ce">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link 
                    target="_blank"
                    to="https://www.instagram.com/fortesol.ce/">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link       
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol">
                      <i className="fa fa-whatsapp"></i>
                    </Link>
                  </li>
         
          </ul>
        </div>
      </div>
    </nav>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
               <p><strong>Seja bem vindo, somos a Fortesol Energia</strong></p>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <br />
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Saiba mais
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
