import React from "react";
import { Link } from "react-router-dom";

export const ImageService = ({ title, link, smallImage }) => {
  return (
    <div className="services-item">
      <div className="services-bg">
        {" "}
        <Link to={link} title={title} data-lightbox-gallery="gallery1">
          <div className="services-text">
            <h4>{title}</h4>
            <Link
                  to={link}
                  className="btn btn-custom btn-lg page-scroll"
                  >
                  saiba mais
                </Link>{" "}
          </div>
          <img src={smallImage} className="img-service" alt={title} />{" "}
        </Link>{" "}
      </div>
    </div>
  );
};