import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const NrDez = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">

    



                    <div className="post-items">
                        <img src="img/portfolio/nr-10.png" alt="" className="img-item" />
                        <div className="text-item">
                           
                            <p><strong>O que é o Laudo de NR10 ?</strong></p>
                            <br />
                            <br />
                            <p className="text-justify">O Laudo de NR10 é um documento que atesta a conformidade de instalações elétricas com as normas de segurança estabelecidas pela NR10. A NR10 é uma norma regulamentadora que estabelece os requisitos e condições mínimas para garantir a segurança e a saúde dos trabalhadores que interagem com instalações elétricas e serviços com eletricidade.</p>
                            <p className="text-justify">
                                O Laudo Elétrico, ou Laudo das Inspeções do Sistema Elétrico conforme NR10, é um documento técnico realizado por um profissional legalmente habilitado (Engenheiro Eletricista), contendo o registro das inspeções nas instalações elétricas do cliente (cabines primárias, subestações, painéis e instalações prediais), relatório fotográfico e relação de não conformidades e sugestões para adequação, conforme as normas ABNT NBR 5410, 14039, 5419 e NR10.
                                <br />
                                <br />
                                Além da ART(CREA) é parte integrante das inspeções e laudo elétrico o diagnóstico do cumprimento dos itens obrigatórios no PIE (Prontuário das Instalações Elétricas) da NR10, conforme os itens da NR10 10.2.4 e 10.2.5, são eles:
                                <br />
                                <br />

                                a) conjunto de procedimentos e instruções técnicas e administrativas de segurança e saúde, implantadas e relacionadas a esta NR e descrição das medidas de controle existentes;
                                <br />
                                <br />

                                b) documentação das inspeções e medições do sistema de proteção contra descargas atmosféricas e aterramentos elétricos;
                                <br />
                                <br />

                                c) especificação dos equipamentos de proteção coletiva e individual e o ferramental, aplicáveis conforme determina esta NR;
                                <br />
                                <br />

                                d) documentação comprobatória da qualificação, habilitação, capacitação, autorização dos trabalhadores e dos treinamentos realizados;
                                <br />
                                <br />

                                e) resultados dos testes de isolação elétrica realizados em equipamentos de proteção individual e coletiva;
                                <br />
                                <br />

                                f) certificações dos equipamentos e materiais elétricos em áreas classificadas; e
                                <br />
                                <br />


                                g) relatório técnico das inspeções atualizadas com recomendações, cronogramas de adequações, contemplando as alíneas de “a” a “f”.
                                <br />
                                <br />
                            </p>
                            <h1>Quais órgãos exigem o Laudo de NR10 ?</h1>
                            <br />
                            <br />
                            <p className="text-justify">O Laudo de NR10 é exigido por diversos órgãos, como o Ministério do Trabalho e Emprego (MTE), a Agência Nacional de Energia Elétrica (ANEEL), a Agência Nacional do Petróleo, Gás Natural e Biocombustíveis (ANP), a Agência Nacional de Telecomunicações (ANATEL), entre outros. Além disso, o laudo é exigido por seguradoras e empresas de auditoria.</p>
                            <br />
                            <br />
                            <h1>Por que fazer o Laudo de NR10 ?</h1>
                            <br />
                            <br />
                            <p className="text-justify">O Laudo de NR10 é fundamental para garantir a segurança dos trabalhadores que interagem com instalações elétricas e serviços com eletricidade. Além disso, o laudo é importante para evitar acidentes de trabalho, prevenir danos materiais e proteger o patrimônio da empresa.</p>
                            <p className="text-justify">O Laudo de NR10 também é importante para garantir a conformidade das instalações elétricas com as normas de segurança estabelecidas pela NR10. Dessa forma, o laudo ajuda a evitar multas e penalidades por descumprimento das normas de segurança.</p>
                            <p className="text-justify">Além disso, o Laudo de NR10 é importante para identificar e corrigir possíveis falhas e irregularidades nas instalações elétricas, garantindo a segurança dos trabalhadores e a integridade das instalações.</p>
                            <p className="text-justify">Por fim, o Laudo de NR10 é importante para demonstrar o compromisso da empresa com a segurança e a saúde dos trabalhadores, o que contribui para a imagem e a reputação da empresa no mercado.</p>

                            <h1> Onde posso fazer o Laudo de NR10 ?</h1>
                            <br />
                            <p className="text-justify">A Fortesol Energia é uma empresa especializada na elaboração de Laudos de NR10. Contamos com uma equipe de profissionais qualificados e experientes, que estão preparados para realizar inspeções e emitir laudos de acordo com as normas de segurança estabelecidas pela NR10.</p>
                            <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                        <br />
                <br />
                <br />
                <div className="row">


                    <div className="portfolio-items ">
                        <div className="carrosel">

                            <Swiper
                                autoplay={{ delay: 2500 }}
                                slidesPerView={sildPreView}
                                navigation
                                pagination={{ clickable: true }}

                            >
                                {props.data
                                    ? props.data.services.map((d, i) => (
                                        <SwiperSlide key={i} >
                                            <Image
                                                title={d.title}
                                                link={d.link}
                                                smallImage={d.smallImage}
                                            />
                                        </SwiperSlide>
                                    ))
                                    : "Loading..."
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
                    <Link
                        target="_blank"
                        to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                        className=" btn btn-wapp  btn-lg page-scroll"

                    >
                        <i className="fa fa-whatsapp"></i>
                    </Link>{" "}
                </div>
            </div>
                



<Contact />

        </>
    );
};

