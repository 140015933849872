import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const EstudoSeletividade = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">

                    <div className="post-items">
                  <img src="img/portfolio/seletividade.png" className="img-item" alt="" />
        <div className="text-item">
                        
                        <p><strong>O que é estudo de seletividade?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O estudo de seletividade é um estudo técnico que tem como objetivo garantir a proteção de um sistema elétrico, garantindo que, em caso de falha, apenas o dispositivo mais próximo do ponto de falha seja desligado, mantendo o restante do sistema energizado. </p>

                        <h1>Por que fazer um estudo de seletividade?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O estudo de seletividade é fundamental para garantir a segurança e a confiabilidade de um sistema elétrico. Ele permite identificar os dispositivos de proteção que devem ser instalados em um sistema elétrico para garantir que, em caso de falha, apenas o dispositivo mais próximo do ponto de falha seja desligado, mantendo o restante do sistema energizado. </p>
                        <p className="text-justify">Além disso, o estudo de seletividade permite identificar os dispositivos de proteção que devem ser ajustados para garantir que, em caso de falha, apenas o dispositivo mais próximo do ponto de falha seja desligado, mantendo o restante do sistema energizado. </p>
                        <p className="text-justify">Dessa forma, o estudo de seletividade é fundamental para garantir a segurança e a confiabilidade de um sistema elétrico, evitando interrupções desnecessárias no fornecimento de energia e garantindo a continuidade das operações.</p>

                        <h1>Como é feito um estudo de seletividade?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O estudo de seletividade é feito por meio de simulações computacionais que permitem identificar os dispositivos de proteção que devem ser instalados e ajustados em um sistema elétrico para garantir a seletividade do sistema. </p>
                        <p className="text-justify">Para isso, são consideradas as características dos dispositivos de proteção, como corrente de curto-circuito, tempo de atuação e curvas de atuação, bem como as características do sistema elétrico, como impedância de curto-circuito, corrente de carga e corrente de falta.</p>
                        <p className="text-justify">Com base nessas informações, são realizadas simulações computacionais que permitem identificar os dispositivos de proteção que devem ser instalados e ajustados em um sistema elétrico para garantir a seletividade do sistema. </p>
                        <p className="text-justify">Além disso, o estudo de seletividade também permite identificar os dispositivos de proteção que devem ser ajustados para garantir a seletividade do sistema, evitando interrupções desnecessárias no fornecimento de energia e garantindo a continuidade das operações.</p>

                        <h1>Quais são os benefícios de um estudo de seletividade?</h1>
                        <br />
                        <br />

                        <p className="text-justify">O estudo de seletividade traz diversos benefícios para um sistema elétrico, como:</p>

                        <ul>
                            <li>Garantia da proteção do sistema elétrico;</li>
                            <li>Redução do tempo de desligamento em caso de falha;</li>
                            <li>Redução do impacto de falhas no fornecimento de energia;</li>
                            <li>Redução dos custos de manutenção;</li>
                            <li>Garantia da continuidade das operações.</li>
                        </ul>

                        <p className="text-justify">Além disso, o estudo de seletividade permite identificar os dispositivos de proteção que devem ser instalados e ajustados em um sistema elétrico para garantir a seletividade do sistema, evitando interrupções desnecessárias no fornecimento de energia e garantindo a continuidade das operações.</p>

                        <h1>Por que escolher a Fortesol para realizar um estudo de seletividade?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A Fortesol é uma empresa especializada em soluções energéticas que conta com uma equipe de profissionais altamente qualificados e experientes na realização de estudos de seletividade. </p>
                        <p className="text-justify">Com mais de 30 anos de experiência no mercado, a Fortesol oferece soluções personalizadas e inovadoras para atender as necessidades de seus clientes, garantindo a segurança e a confiabilidade de seus sistemas elétricos.</p>

                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>
                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





            <Contact    />

        </>
    );
};

