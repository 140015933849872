import React from "react";
import { Link } from "react-router-dom";
export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/quem-somos.jpg" className="img-about " alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Quem somos</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Porque nos escolher?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
              
              <div  className="row align-about">
              <div className="col-xs-12 col-md-6 text-pro">

                <h1>SOLICITE SEU ORÇAMENTO</h1>
                <br />
                <br />
                <Link
                  to="/budget"
                  className="btn-pulsando btn btn-custom btn-lg page-scroll"
                >
                  orçamento
                </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="img-pro" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
