
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Main } from "./blog/main";
import { Budget } from "./components/budget";
import { Services } from "./components/services";
import { Pie } from "./blog/pie";
import { Aterramento } from "./blog/aterramento";
import { SPDA } from "./blog/spda";
import { Termografia } from "./blog/termografia";
import { NrDez } from "./blog/nr-10";
import { ICMS } from "./blog/icms";
import { AreasClassificadas } from "./blog/areas-classificadas";
import { ResistividadeSolo } from "./blog/resistividade-solo";
import { ResistenciaIsolamento } from "./blog/resistencia-isolamento";
import { AnaliseEnergia } from "./blog/analise-energia";
import { SDAI } from "./blog/sdai";
import { SAVE } from "./blog/save";
import { InstalacoesEletricas } from "./blog/instalacoes-eletricas";
import { ParametrizacaoReles } from "./blog/parametrizacao-reles";
import { Subestacao } from "./blog/subestacao";
import { QuadroEletrico } from "./blog/quadros";
import { FatorPotencia } from "./blog/fator-potencia";
import { EstudoSeletividade } from "./blog/seletividade";
import { Sistema } from "./blog/sistema";
import JsonData from "./data/data.json";
import { useEffect, useState } from "react";
import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
register();
const Router = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/services" element={<Services data={landingPageData.Gallery} />} />
          <Route path="/blog" element={<Main data={landingPageData.Blog} />} />
          <Route path="/pie" element={<Pie data={landingPageData.Blog} />} />
          <Route path="/laudo-de-aterramento" element={<Aterramento data={landingPageData.Blog} />} />
          <Route path="/laudo-spda" element={<SPDA data={landingPageData.Blog} />} />
          <Route path="/termografia" element={<Termografia data={landingPageData.Blog} />} />
          <Route path="/laudo-eletrico-nr10" element={<NrDez data={landingPageData.Blog} />} />
          <Route path="/icms" element={<ICMS data={landingPageData.Blog} />} />
          <Route path="/areas-classificadas" element={<AreasClassificadas data={landingPageData.Blog} />} />
          <Route path="/resistividade-do-solo" element={<ResistividadeSolo data={landingPageData.Blog} />} />
          <Route path="/resistencia-de-isolamento" element={<ResistenciaIsolamento data={landingPageData.Blog} />} />
          <Route path="/analise-de-energia" element={<AnaliseEnergia data={landingPageData.Blog} />} />
          <Route path="/sistema-de-deteccao-de-incendio" element={<SDAI data={landingPageData.Blog} />} />
          <Route path="/save" element={<SAVE data={landingPageData.Blog} />} />
          <Route path="/instalacoes-eletricas" element={<InstalacoesEletricas data={landingPageData.Blog} />} />
          <Route path="/parametrizacao-de-reles" element={<ParametrizacaoReles data={landingPageData.Blog} />} />
          <Route path="/subestacao" element={<Subestacao data={landingPageData.Blog} />} />
          <Route path="/quadro-eletrico" element={<QuadroEletrico data={landingPageData.Blog} />} />
          <Route path="/fator-de-potencia" element={<FatorPotencia data={landingPageData.Blog} />} />
          <Route path="/estudo-de-seletividade" element={<EstudoSeletividade data={landingPageData.Blog} />} />
          <Route path="/energia-solar" element={<Sistema data={landingPageData.Blog} />} />
        </Routes>

      </BrowserRouter>
     <img src="img/crea.png" className="crea-img"/>   
    </>

  );
};

export default Router;
