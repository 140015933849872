import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";



const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Pie = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">

                    <div className="post-items">
                        <img src="img/portfolio/pie.png" className='img-item' alt="" />
                       <div className="text-item">
                       
                        <p><strong>Por que é importante ter um Prontuário de Instalações Elétricas?</strong></p>
                        <br />
                        <p className='text-aling'>A eletricidade é uma fonte essencial de energia que, quando não controlada adequadamente, pode representar riscos significativos para trabalhadores e instalações em geral. E nesse contexto, o Prontuário de Instalações Elétricas (PIE) surge como uma ferramenta essencial para garantir a segurança e a conformidade nas empresas.
                            <br />
                            <br />
                            O PIE é um documento obrigatório, regulamentado por uma norma regulamentadora, a NR-10, que reúne todas as informações relevantes sobre as instalações elétricas de um estabelecimento.
                            <br />
                            <br />

                            Neste artigo, vamos explorar tudo sobre o Prontuário de Instalações Elétricas. Continue a leitura e confira:</p>
                        <ul>
                            <li>
                                O que é o Prontuário de Instalações Elétricas (PIE)?
                            </li>
                            <li>
                                Quais os principais objetivos do Prontuário de Instalações Elétricas (PIE)?
                            </li>
                            <li>
                                Passos para considerar uma instalação desenergizada
                            </li>
                        </ul>
                        <h1>O que é o Prontuário de Instalações Elétricas (PIE)?</h1>
                        <br />
                        <p className='text-aling'>O Prontuário de Instalações Elétricas (PIE) é um documento que reúne todas as informações relevantes sobre as instalações elétricas de um estabelecimento. Ele é regulamentado pela Norma Regulamentadora NR-10, que estabelece os requisitos e condições mínimas para garantir a segurança dos trabalhadores que interagem com instalações elétricas.
                            <br />
                            <br />
                            O PIE tem como objetivo garantir a segurança dos trabalhadores que atuam nesse ambiente, além de facilitar o controle e a gestão das instalações elétricas. Ele engloba processos desde o projeto até a manutenção e operação, contemplando todos os aspectos que envolvem o uso da eletricidade no ambiente de trabalho.
                            <br />
                            <br />
                            O Prontuário de Instalações Elétricas é regulamentado pela Norma Regulamentadora número 10 (NR-10) do Ministério do Trabalho e Emprego. A partir dela se estabelecem os requisitos mínimos de segurança e saúde no trabalho em instalações elétricas, definindo diretrizes para elaboração e manutenção do prontuário NR-10.

                            <br />
                            <br />
                            A NR-10 se aplica a todas as fases e etapas do setor elétrico, desde o projeto, e engloba todas as atividades relacionadas ao uso e à geração de energia elétrica.
                            <br />
                            <br />
                            Ou seja, abrange desde a distribuição até o consumo final, sendo fundamental que todas as etapas do setor elétrico estejam em conformidade com as exigências da norma.
                            <br />
                            <br />
                        </p>
                        <h1>Quais os principais objetivos do Prontuário de Instalações Elétricas (PIE)?</h1>
                        <br />
                        <p className='text-aling'>O prontuário NR-10 tem como principais objetivos promover a segurança dos trabalhadores e a integridade das instalações elétricas.
                            <br />
                            <br />
                            O prontuário busca garantir que todas as medidas de prevenção de acidentes sejam adotadas, evitando riscos como choques elétricos, incêndios e explosões.
                            <br />
                            <br />

                            Além disso, o PIE auxilia no cumprimento das exigências legais e normativas, contribuindo para a conformidade das empresas.
                            <br />
                            <br />

                            Dessa forma, o PIE NR-10 desempenha papel fundamental na segurança do trabalho, pois permite identificar e controlar os riscos associados às instalações elétricas.
                            <br />
                            <br />

                            É ele que fornece informações detalhadas sobre os componentes elétricos, a infraestrutura, os procedimentos de trabalho e as medidas de proteção adotadas.
                            <br />
                            <br />

                            Assim, auxilia na prevenção de acidentes, na capacitação dos trabalhadores e na adoção de medidas corretivas e preventivas.
                            <br />
                            <br />
                        </p>


                        <h1>Passos para considerar uma instalação desenergizada</h1>
                        <br />

                        <p className='text-aling'>A desenergização é um procedimento fundamental para garantir a segurança dos trabalhadores que atuam em instalações elétricas. Ela consiste em retirar a energia elétrica de um circuito ou equipamento, eliminando os riscos de choques elétricos e outros acidentes.
                            <br />
                            <br />

                            Para realizar a desenergização de forma segura, é necessário seguir alguns passos importantes. Confira a seguir:
                            <br />
                            <br />
                            <ul>
                                <li>1. Planejamento: Antes de iniciar a desenergização, é fundamental planejar todas as etapas do procedimento. Isso inclui identificar os circuitos e equipamentos que serão desligados, definir os procedimentos de trabalho e garantir que todos os trabalhadores envolvidos estejam capacitados.</li>
                                <li>2. Identificação dos circuitos: É importante identificar os circuitos e equipamentos que serão desligados, garantindo que todos os pontos de energia estejam desenergizados.</li>
                                <li>3. Bloqueio e etiquetagem: Após identificar os circuitos, é necessário bloquear e etiquetar os dispositivos de manobra, impedindo que a energia seja religada acidentalmente. Os bloqueios e etiquetas devem ser padronizados e de fácil identificação.</li>
                                <li>4. Verificação da desenergização: Antes de iniciar os trabalhos, é fundamental verificar se a energia foi desligada corretamente. Isso pode ser feito por meio de um teste de ausência de tensão, que garante que não há energia nos circuitos.</li>
                                <li>5. Aterramento temporário: Em alguns casos, é necessário realizar o aterramento temporário dos circuitos desenergizados, garantindo a segurança dos trabalhadores durante a execução dos serviços.</li>
                                <li>6. Autorização de trabalho: Após garantir que a energia foi desligada corretamente, é necessário emitir uma autorização de trabalho, que autoriza os trabalhadores a realizar os serviços programados.</li>
                                <li>7. Execução dos serviços: Com a autorização de trabalho em mãos, os trabalhadores podem iniciar os serviços programados, seguindo os procedimentos de segurança estabelecidos.</li>
                                <li>8. Restabelecimento da energia: Após a conclusão dos trabalhos, é necessário restabelecer a energia elétrica, seguindo os procedimentos de segurança estabelecidos.</li>
                            </ul>
                            <br />
                            <br />
                            A desenergização é um procedimento fundamental para garantir a segurança dos trabalhadores que atuam em instalações elétricas. Por isso, é importante seguir todos os passos corretamente, garantindo a integridade dos trabalhadores e das instalações.
                            <br />
                            <br />

                            Em resumo, o Prontuário de Instalações Elétricas (PIE) é um documento essencial para garantir a segurança e a conformidade das instalações elétricas de um estabelecimento. Ele reúne todas as informações relevantes sobre as instalações elétricas, facilitando o controle e a gestão dos riscos associados à eletricidade.
                        </p>

                        <h1>Onde fazer o Prontuário de Instalações Elétricas (PIE)?</h1>
                        <br />
                        <br />
                        <p className='text-aling'>A Fortesol Energia é uma empresa especializada em serviços elétricos, que oferece soluções completas para a segurança e a eficiência energética de empresas de diversos segmentos.
                            <br />
                            <br />
                            Contamos com uma equipe de profissionais qualificados e experientes, que estão preparados para elaborar o Prontuário de Instalações Elétricas (PIE) da sua empresa, garantindo a segurança dos trabalhadores e a conformidade com as normas regulamentadoras.
                            <br />
                            <br />
                            Entre em contato conosco e saiba mais sobre os nossos serviços de segurança elétrica. Estamos à disposição para atender as suas necessidades e oferecer as melhores soluções para a sua empresa.
                        </p>
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





       <Contact />
        </>
    );
};

