import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const ResistenciaIsolamento = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">






                    <div className="post-items">
                        <img src="img/portfolio/resistencia-isolamento.png" alt="" className="img-item" />
                        <div className="text-item">
                            <p><strong>O que Resistência de Isolamento?</strong></p>
                            <br />
                            <br />
                            <p className="text-justify">A resistência de isolamento é uma medida da resistência de um componente ou material isolante ao fluxo de corrente elétrica. A resistência de isolamento é medida em ohms e é a resistência oferecida por um isolante ao fluxo de corrente elétrica. A resistência de isolamento é uma medida da resistência de um componente ou material isolante ao fluxo de corrente elétrica. A resistência de isolamento é medida em ohms e é a resistência oferecida por um isolante ao fluxo de corrente elétrica.</p>

                            <h1>O que faz o teste de Resistência de Isolamento?</h1>
                            <br />
                            <br />
                            <p className="text-justify">É o teste com a finalidade de analisar, encontrar, diagnosticar e prevenir possíveis falhas na isolação de condutores e equipamentos. A aplicação consiste entre dois eletrodos em contato com o equipamento ou condutor a ser analisado com V0.
                                <br />
                                <br />
                                A tensão contínua aplicada para a realização do teste deve ser 500 +-10.000V.  A tensão deve se manter suficientemente estável para que a corrente que surgir quando a tensão variar seja desprezível em comparação com a corrente que percorre o corpo de prova.

                                <br />
                                <br />
                                O ensaio consiste em aplicar no isolamento uma tensão em corrente contínua com valores entre 500 V e 10.000 V. Isso provocará a circulação de um fluxo pequeno de corrente.  A resistência do material isolante pode ser determinada pelo método da ponte ou pela medição da corrente e tensão.

                                <br />
                                <br />
                                O aparelho mais indicado para esse teste é megômetro que deve ser adotado com a tensão estabelecida pela norma ou pelo fabricante do equipamento.

                                <br />
                                <br />
                                A resistência resultante neste ensaio é a soma da resistência interna do condutor (valor pequeno) mais a resistência de isolação, que é dividida em três componentes (subcorrentes) independentes:

                                <br />
                                <br />
                                a) Corrente de deslocamento ou corrente de carga capacitiva (IC);
                                <br />
                                <br />

                                b) Corrente de absorção (IA);
                                <br />
                                <br />

                                c) Corrente de dispersão ou corrente de fuga por meio do dielétrico.
                                <br />
                                <br />

                                A resistência de isolamento é a resistência total oferecida pelo isolamento ao fluxo de corrente elétrica. A resistência de isolamento é medida em ohms e é a resistência oferecida por um isolante ao fluxo de corrente elétrica.</p>
                            <br />
                            <br />
                            <h1>Por que fazer o teste de Resistência de Isolamento?</h1>
                            <br />
                            <br />
                            <p className="text-justify">O teste de resistência de isolamento é um dos testes mais importantes que podem ser realizados em equipamentos elétricos. Ele é usado para verificar a integridade do isolamento de um equipamento elétrico e para garantir que ele está funcionando corretamente. O teste de resistência de isolamento é um teste não destrutivo que pode ser realizado em equipamentos elétricos sem desligá-los da rede elétrica. Ele é usado para verificar se o isolamento de um equipamento elétrico está em boas condições e se ele está protegendo o equipamento de curtos-circuitos e outras falhas elétricas. O teste de resistência de isolamento é um teste não destrutivo que pode ser realizado em equipamentos elétricos sem desligá-los da rede elétrica. Ele é usado para verificar se o isolamento de um equipamento elétrico está em boas condições e se ele está protegendo o equipamento de curtos-circuitos e outras falhas elétricas.</p>

                            <h1>Onde fazer Teste de Resistência de Isolamento?</h1>
                            <br />
                            <br />
                            <p className="text-justify">A Fortesol Energia é uma empresa especializada em testes de resistência de isolamento. Nossos técnicos são altamente qualificados e possuem anos de experiência na realização de testes de resistência de isolamento em equipamentos elétricos. Nós usamos equipamentos de última geração para garantir que os testes sejam realizados com precisão e segurança. Nós oferecemos serviços de teste de resistência de isolamento para uma ampla gama de equipamentos elétricos, incluindo transformadores, motores, geradores, painéis elétricos e muito mais. Nós oferecemos serviços de teste de resistência de isolamento para uma ampla gama de equipamentos elétricos, incluindo transformadores, motores, geradores, painéis elétricos e muito mais.</p>

                            <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className=" btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





<Contact />

        </>
    );
};

