import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Link } from "react-router-dom"; 
import { GiPartyPopper } from "react-icons/gi";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Budget = (props) => {
  const newCnpj = (e) => {
    let cnpj = e.target.value;
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");

    if (cnpj.length <= 18) {
      cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
    }
    if (cnpj.length <= 14) {
      cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
    }
    if (cnpj.length > 19) {
      cnpj = cnpj.substring(0, 18);

    }
    e.target.value = cnpj;
    setState((prevState) => ({ ...prevState, cnpj: cnpj }));
  }

  const [{ name, email, phone, empresa, cnpj, services, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });


  const handleSubmit = (e) => {
    e.preventDefault();



    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    emailjs
      .sendForm("OutlookMessage", "template_2zahl6s", e.target, "pPh3oADNFGfeyKorv")
      .then(
        (result) => {

          const modal = document.querySelector('.off');
          modal.click();
          clearState();
          setTimeout(() => {
            window.location.reload();
          }
            , 3000);
        },
        (error) => {
          const modal = document.querySelector('.error');
          modal.click();
          clearState();
          setTimeout(() => {
            window.location.reload();
          }
            , 3000);
        }
      );
  };
  return (
    <div>
      <div id="budget">
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <Link to="/">
                <img src="img/logosemfundo.png" alt="" height="55px" />
              </Link>{" "}
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="/#header" className="page-scroll">
                    Home
                  </a>
                </li>

                <li>
                  <Link to="/services" className="page-scroll">
                    Serviços
                  </Link>
                </li>
                {/*<li>
              <Link to="/#testimonials" className="page-scroll">
                Clientes
              </Link>
  </li>*/}
                <li>
                  <a href="/#about" className="page-scroll">
                    Quem somos
                  </a>
                </li>
                <li>
                  <Link to="/blog" className="page-scroll">
                    Blog
                  </Link>
                </li>
                <li>
                  <a href="/#contact" className="page-scroll">
                    Contato
                  </a>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/fortesol.ce">
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/fortesol.ce/">
                    <i className="fa fa-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol">
                    <i className="fa fa-whatsapp"></i>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </nav>


        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">

                <h2>Faça seu orçamento</h2>
                <h1>
                  Temos a solução para sua necessidade, entre em contato conosco
                  para solicitar um orçamento. Nossa equipe está de prontidão para
                  atendê-lo.{" "}
                </h1>

              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone/Whatsapp"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="empresa"
                        name="empresa"
                        className="form-control"
                        placeholder="Empresa"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        className="form-control"
                        placeholder="CNPJ"

                        required
                        onChange={newCnpj}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        type="text"
                        id="services"
                        name="services"
                        className="form-control"
                        placeholder="Serviços"
                        required
                        onChange={handleChange}
                      >
                        <option value="0">Selecione o Serviço...</option>
                        <option value="PIE – Prontuário de Instalações Elétricas">PIE – Prontuário de Instalações Elétricas </option>
                        <option value="Laudo de Aterramento">Laudo de Aterramento </option>
                        <option value="Laudo de SPDA">Laudo de SPDA</option>
                        <option value="Laudo Elétrico NR-10">Laudo Elétrico NR-10</option>
                        <option value="Laudo de ICMS">Laudo de ICMS </option>
                        <option value="Laudo de Áreas Classificada">Laudo de Áreas Classificadas </option>
                        <option value="Laudo de Termografia">Laudo de Termografia </option>
                        <option value="Laudo de Resistividade do Solo">Laudo de Resistividade do Solo</option>
                        <option value="Laudo de Resistência de Isolamento">Laudo de Resistência de Isolamento </option>
                        <option value="Laudo de Análise de Energia">Análise de Energia</option>
                        <option value="SDAI – Projetos de Sistema de Detecção e Alarme de Incêndio">SDAI – Projetos de Sistema de Detecção e Alarme de Incêndio </option>
                        <option value="Projeto Carregador de Veículo Elétrico">Projeto Carregador de Veículo Elétrico </option>
                        <option value="Projetos de Instalações Elétricas">Projeto de Instalações Elétricas </option>
                        <option value="Instalações Elétricas">Instalações Elétricas </option>
                        <option value="Projeto Subestação">Projeto Subestação</option>
                        <option value="Projeto de Quadros Elétricos">Projeto de Quadros Elétricos </option>
                        <option value="Correção de Fator de Potência – Energia Reativa">Correção de Fator de Potência – Energia Reativa </option>
                        <option value="Serviços Elétricos">Serviços Elétricos </option>
                        <option value="Energia Solar">Energia Solar</option>

                      </select>

                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Descreva sua necessidade..."
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  enviar orçamento
                </button>
              </form>
              <button className="off" data-toggle="modal" data-target="#ExemploModalCentralizado" />
              <div className="modal fade" id="ExemploModalCentralizado" tabindex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-body">
                    <div>

                      <h1><span><GiPartyPopper />{' '}</span>Orçamento enviado com sucesso!{' '}<span><GiPartyPopper /></span></h1>
                      <p><span>fortesol</span> agradece a preferência!</p>
                    </div>
                  </div>
                </div>
              </div>
              <button className="error" data-toggle="modal" data-target="#ExemploModalCentralizado" />
              <div className="modal fade" id="ExemploModalCentralizado" tabindex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-body red">
                    <div>
                      <h1>ERRO, por favor, tente novamente!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 img">
            <img
              className="img-orcamento"
              src="img/orcamento.png" alt="" />

          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.facebook.com/fortesol.ce">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/fortesol.ce/">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol">
                      <i className="fa fa-whatsapp"></i>
                    </Link>
                  </li>
                  <li className="none">
                    <p>
                      <span>
                        <i className="fa fa-phone "></i> (85) 4141-4900
                      </span>{" "}

                    </p>
                  </li>
                  <li className="none">
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i> contato@fortesol.com.br
                      </span>{" "}

                    </p>
                  </li>
                  <li className="none">
                    <p>
                      <span>
                        <i className="fa fa-map-marker"></i> R. Luiza Teixeira de Araújo, 69 - Pentecoste - CE
                      </span>

                    </p>
                  </li>
                </ul>

              </div>

            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 FORTESOL ENERGIA. Design by{" "}
            <a  target="_blank"  href="https://www.instagram.com/sampaio_technology" rel="nofollow">
              Sampaio Technology
            </a>
          </p>
        </div>
      </div>

      <Link
        target="_blank"
        to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
        className="btn btn-wapp  btn-lg page-scroll"

      >
        <i className="fa fa-whatsapp"></i>
      </Link>{" "}
    </div>
  );
};
