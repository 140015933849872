import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const QuadroEletrico = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
               




                    <div className="post-items">
                   <img src="img/portfolio/quadros.png" alt="" className="img-item" />

                       <div className="text-item">
                        <p><strong>O que é Projeto de Quadro Elétrico?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O projeto de quadro elétrico é um documento técnico que contém todas as informações necessárias para a instalação de um quadro elétrico. Ele é elaborado por um engenheiro eletricista e deve seguir as normas técnicas vigentes, garantindo a segurança e o bom funcionamento do sistema elétrico.</p>
                        <p className="text-justify">O projeto de quadro elétrico é fundamental para garantir a eficiência e a segurança das instalações elétricas de um ambiente. Ele deve ser elaborado de acordo com as necessidades do cliente e as características do local, levando em consideração a demanda de energia, a quantidade de circuitos, a potência dos equipamentos, entre outros fatores.</p>
                    <p className="text-justify">Além disso, o projeto de quadro elétrico deve conter todas as informações necessárias para a montagem e instalação do quadro, como o diagrama unifilar, a lista de materiais, as especificações técnicas dos equipamentos, entre outros detalhes. Ele também deve ser aprovado por um engenheiro eletricista responsável, garantindo a sua qualidade e segurança.</p>
                    <p className="text-justify">Por fim, o projeto de quadro elétrico deve ser seguido à risca durante a instalação do quadro, garantindo que todas as normas técnicas sejam cumpridas e que o sistema elétrico funcione corretamente. Ele também deve ser arquivado e mantido atualizado, para facilitar a manutenção e eventuais modificações no sistema elétrico.</p>

                    <h1>Por que fazer um Projeto de Quadro Elétrico?</h1>
                    <br />
                    <br />
                    <p className="text-justify">O projeto de quadro elétrico é fundamental para garantir a segurança e o bom funcionamento das instalações elétricas de um ambiente. Ele é elaborado por um engenheiro eletricista qualificado e deve seguir as normas técnicas vigentes, garantindo a qualidade e a eficiência do sistema elétrico.</p>
                    <p className="text-justify">Além disso, o projeto de quadro elétrico permite dimensionar corretamente o quadro elétrico, levando em consideração a demanda de energia, a quantidade de circuitos, a potência dos equipamentos, entre outros fatores. Isso garante que o sistema elétrico funcione de forma segura e eficiente, evitando problemas como curtos-circuitos, sobrecargas e falhas no sistema.</p>
                    <p className="text-justify">Por fim, o projeto de quadro elétrico facilita a instalação e a manutenção do sistema elétrico, pois contém todas as informações necessárias para a montagem e o funcionamento do quadro. Ele também permite identificar possíveis falhas e problemas no sistema, facilitando a sua correção e evitando danos maiores.</p>

                    <h1>Como fazer um Projeto de Quadro Elétrico?</h1>
                    <br />
                    <br />
                    <p className="text-justify">Para fazer um projeto de quadro elétrico, é necessário contratar um engenheiro eletricista qualificado, que irá analisar as necessidades do cliente e as características do local, levando em consideração a demanda de energia, a quantidade de circuitos, a potência dos equipamentos, entre outros fatores.</p>
                    <p className="text-justify">O engenheiro eletricista irá elaborar o projeto de quadro elétrico de acordo com as normas técnicas vigentes, garantindo a segurança e o bom funcionamento do sistema elétrico. Ele irá dimensionar corretamente o quadro elétrico, especificar os equipamentos necessários, elaborar o diagrama unifilar, entre outras atividades.</p>
                    <p className="text-justify">Por fim, o projeto de quadro elétrico deve ser aprovado por um engenheiro eletricista responsável, garantindo a sua qualidade e segurança. Ele deve ser seguido à risca durante a instalação do quadro, para garantir que todas as normas técnicas sejam cumpridas e que o sistema elétrico funcione corretamente.</p>
                    <p className="text-justify">Após a instalação do quadro elétrico, o projeto deve ser arquivado e mantido atualizado, para facilitar a manutenção e eventuais modificações no sistema elétrico. Ele também deve ser disponibilizado para os responsáveis pela manutenção e operação do sistema, para garantir a sua correta utilização.</p>

                    <h1>Conclusão</h1>
                    <br />
                    <br />
                    <p className="text-justify">O projeto de quadro elétrico é fundamental para garantir a segurança e o bom funcionamento das instalações elétricas de um ambiente. Ele deve ser elaborado por um engenheiro eletricista qualificado e seguir as normas técnicas vigentes, garantindo a qualidade e a eficiência do sistema elétrico.</p>

                    <h1>Conte com a Fortesol para fazer o seu Projeto de Quadro Elétrico</h1>
                    <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>




<Contact />

        </>
    );
};

