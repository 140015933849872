import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const SPDA = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">





                    <div className="post-items">
                        <img src="img/portfolio/spda.png" alt="" className="img-item" />
                        <div className="text-item">

                            <p><strong>O que é laudo de SPDA?</strong></p>
                            <br />
                            <br />
                            <p className="text-justify">
                                O Laudo de SPDA é um documento técnico que atesta a conformidade do sistema de proteção contra descargas atmosféricas, com as normas técnicas vigentes. O laudo é obrigatório para todas as edificações, conforme a NBR 5419/2015, que estabelece as condições mínimas de proteção contra descargas atmosféricas.
                            </p>
                            <p className="text-justify">
                                Com a alta incidência de raios no Brasil, garantir a segurança de seu para-raios é essencial. Para isso, é necessário manter em dia o laudo SPDA (Sistema de Proteção Contra Descargas Atmosféricas). Você tem dúvidas sobre o assunto, como “laudo de para raio tem validade” e “como fazer um laudo técnico de para-raio”? Então continue a leitura!
                                <br />
                                <br />
                                A manutenção do laudo técnico de SPDA é fundamental é deve ser realizada a cada seis meses, garantindo a segurança de seu edifício. Dito isso, saiba o que é o laudo SPDA, como fazer e qual a validade desse documento fundamental em empresas que possuem para-raios.
                            </p>
                            <br />
                            <br />
                            <h1>Laudo de SPDA, Aterramento e Continuidade Elétrica</h1>
                            <br />
                            <br />
                            <p className="text-justify">O laudo técnico de SPDA, também conhecido como laudo técnico de para-raios, é um documento fundamental para assegurar que os sistemas de proteção contra descargas atmosféricas, ou para-raios, estejam funcionando adequadamente. Neste documento será registrado todas as inspeções, as verificações e medições realizadas no sistema, servindo, futuramente, como parâmetro para análises do sistema e a obtenção de informações relevantes sobre ele.</p>

                            <br />
                            <br />
                            <h1>Por que realizar um Laudo De SPDA?</h1>
                            <br />
                            <br />
                            <p className="text-justify">O laudo SPDA é um documento que assegura a segurança dos Sistema de Proteção Contra Descargas Atmosféricas. Por meio dele, será possível obter informações fundamentais sobre o para-raios, desde sua instalação até seu estado atual, pois é realizada uma análise detalhada e completa por todo sistema.
                                <br />
                                <br />
                                Além disso, a obtenção do documento é obrigatória, sendo uma medida imposta por diversos órgãos como o Ministério do Trabalho e Emprego e pelo Corpo de Bombeiros. Empresas e edifícios que não tiverem o laudo em mãos podem ser multados, além de serem impossibilitados de adquirir outras documentações, como o ISO (International Organization for Standardization, ou Organização Internacional de Padronização, em português) e a contratação de serviços de seguradoras.</p>

                            <h1> Onde posso obter um Laudo de SPDA?</h1>
                            <br />
                            <br />
                            <p className="text-justify"> A Fortesol Energia é uma empresa especializada em laudo de SPDA e oferece serviços de qualidade e confiança. Com uma equipe de profissionais altamente qualificados, a empresa realiza inspeções, verificações e medições em sistemas de proteção contra descargas atmosféricas, garantindo a segurança e a eficiência do para-raios.</p>



                            <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





         <Contact />
        </>
    );
};

