import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Sistema = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                    
       




                    <div className="post-items">
                    <img src="img/portfolio/sistema.png" alt="" className="img-item" />

                     <div className="text-item">
                        <p><strong>O que Sistema Fotovoltaico ?</strong></p>
                       <br />
                       <br />
                        <p className="text-justify">É um sistema de geração de energia elétrica a partir da luz solar. Ele é composto por módulos fotovoltaicos, inversor solar, estrutura de fixação e cabeamento. O sistema fotovoltaico é capaz de gerar energia elétrica de forma limpa e sustentável, reduzindo a conta de luz e a emissão de gases de efeito estufa.</p>
                        <p className="text-justify">O sistema fotovoltaico é uma excelente opção para quem deseja economizar na conta de luz e contribuir para a preservação do meio ambiente. Além disso, o sistema fotovoltaico pode ser instalado em residências, comércios, indústrias e propriedades rurais.</p>

                        <h1>Como funciona um Sistema Fotovoltaico ?</h1>
                       <br />
                       <br />
                        <p className="text-justify">O sistema fotovoltaico funciona de forma simples e eficiente. Os módulos fotovoltaicos captam a luz solar e a transformam em energia elétrica. O inversor solar converte a energia elétrica gerada pelos módulos fotovoltaicos em energia elétrica compatível com a rede elétrica.</p>
                        <p className="text-justify">A energia elétrica gerada pelo sistema fotovoltaico pode ser utilizada para abastecer a rede elétrica da propriedade ou ser armazenada em baterias. O sistema fotovoltaico é uma excelente opção para quem deseja economizar na conta de luz e contribuir para a preservação do meio ambiente.</p>
                        <h1>Quais são as vantagens do Sistema Fotovoltaico ?</h1>
                       <br />
                       <br />
                        <p className="text-justify">O sistema fotovoltaico possui diversas vantagens, como:</p>
                        <ul>
                            <li>Redução da conta de luz;</li>
                            <li>Contribuição para a preservação do meio ambiente;</li>
                            <li>Valorização do imóvel;</li>
                            <li>Independência energética;</li>
                            <li>Retorno do investimento em poucos anos;</li>
                            <li>Garantia de 25 anos para os módulos fotovoltaicos.</li>
                        </ul>
                        <p className="text-justify">O sistema fotovoltaico é uma excelente opção para quem deseja economizar na conta de luz e contribuir para a preservação do meio ambiente. Além disso, o sistema fotovoltaico pode ser instalado em residências, comércios, indústrias e propriedades rurais.</p>
                        <h1>Como instalar um Sistema Fotovoltaico ?</h1>
                       <br />
                       <br />
                        <p className="text-justify">A instalação de um sistema fotovoltaico deve ser realizada por uma empresa especializada e credenciada. A instalação do sistema fotovoltaico é composta por diversas etapas, como:</p>
                        <ul>
                            <li>Projeto do sistema fotovoltaico;</li>
                            <li>Obtenção das licenças e autorizações necessárias;</li>
                            <li>Instalação dos módulos fotovoltaicos, inversor solar e estrutura de fixação;</li>
                            <li>Conexão do sistema fotovoltaico à rede elétrica;</li>
                            <li>Testes e comissionamento do sistema fotovoltaico.</li>
                        </ul>
                        <p className="text-justify">A instalação de um sistema fotovoltaico deve ser realizada por uma empresa especializada e credenciada. A empresa responsável pela instalação do sistema fotovoltaico deve possuir experiência e conhecimento técnico na área de energia solar.</p>
                        <h1>Por que escolher a Fortesol para instalar o seu Sistema Fotovoltaico ?</h1>
                       <br />
                       <br />
                        <p className="text-justify">A Fortesol é uma empresa especializada na instalação de sistemas fotovoltaicos. Nossos profissionais possuem experiência e conhecimento técnico na área de energia solar. Além disso, utilizamos equipamentos de alta qualidade e garantimos a satisfação dos nossos clientes.</p>


                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="https://solar.fortesol.com.br/"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>



<Contact />

        </>
    );
};

