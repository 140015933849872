
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Navigation } from "../components/navigation";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Termografia = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
              
                




                    <div className="post-items">
                <img src="img/portfolio/termografia.png" alt="" className="img-item" />
                      <div className="text-item">
                        <p><strong>O que é laudo de termografia?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">
                            O laudo de inspeção termográfica é uma técnica preditiva de medição de temperatura essencial para o bom funcionamento dos componentes elétricos e para a segurança dos trabalhadores. Durante esta leitura, você vai entender melhor para que serve e como funciona esta inspeção termográfica que também pode ser chamado de laudo das instalações elétricas.
                        </p>
                        <p className="text-justify">
                            A inspeção termográfica faz parte de uma avaliação preditiva ou preventiva de toda a planta elétrica de uma empresa ou indústria. A realização da análise termográfica consiste em realizar a medição  da temperatura de determinados pontos por meio da radiação infravermelha, de modo que o termovisor – equipamento que realiza a medição – seja capaz de detectar e diagnosticar diferentes ranges de  temperatura nos mais variados componentes elétricos, como painéis, transformadores, motores, subestações, etc.
                        </p>
                        <br />
                        <br />
                        <h1>Benefícios do laudo de inspeção termográfica</h1>
                        <br />
                        <br />
                        <p className="text-justify">Essa medição realizada para elaboração do laudo de inspeção termográfica é ideal para impedir danos nos equipamentos, quedas de energia, altos custos com consumo de energia elétrica e também com manutenção corretiva.
                            <br />
                            Além disso, a inspeção termográfica também ajuda a prevenir imprevistos e acidentes gravíssimos, como incêndios que poderiam causar a morte de funcionários e técnicos. Portanto, esse é um tipo de inspeção que qualquer empresa ou indústria deve realizar.
                            <br />
                            Além dos benefícios já citados, também temos as seguintes vantagens ao realizar a análise termográfica: </p>
                        <br />
                        <ul>
                            <li>
                                Garante a segurança das instalações elétricas;
                            </li>
                            <li>
                                Ajuda na redução de manutenções corretivas;
                            </li>
                            <li>
                                Reduz gastos com manutenções diversas;
                            </li>
                            <li>
                                Diminui o consumo de energia elétrica;
                            </li>
                            <li>
                                Diminui o risco de incidentes no local de trabalho;
                            </li>
                            <li>
                                Analisa áreas de alto risco.
                            </li>
                        </ul>
                        <br />
                        <br />
                        <h1>Como é feito o laudo de inspeção termográfica?</h1>
                        <br />
                        <br />
                        <p className="text-justify">
                            O laudo de inspeção termográfica é feito por um técnico especializado que utiliza um equipamento chamado termovisor. Este equipamento é capaz de captar a radiação infravermelha emitida por um corpo e transformar em imagens coloridas que indicam a temperatura de cada ponto analisado.
                        </p>
                        <p className="text-justify">
                            O técnico que realiza a inspeção termográfica deve ser capacitado e ter experiência na área, pois é ele quem vai interpretar as imagens captadas pelo termovisor e identificar possíveis falhas e problemas nas instalações elétricas.
                        </p>
                    <p className="text-justify">
                        Após a realização da inspeção termográfica, o técnico elabora um laudo de inspeção termográfica que contém todas as informações sobre as medições realizadas, as imagens captadas pelo termovisor, as possíveis falhas encontradas e as recomendações para correção dos problemas.
                    </p>

                    <h1>Onde posso fazer o laudo de inspeção termográfica?</h1>
                    <br />
                    <p className="text-justify">
                        A Fortesol Energia é uma empresa especializada em laudo de inspeção termográfica e oferece serviços de alta qualidade para empresas e indústrias de diversos segmentos. Nossos técnicos são altamente capacitados e experientes na realização de inspeções termográficas e elaboração de laudos técnicos.
                    </p>





                    <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>




<Contact    />

        </>
    );
};

