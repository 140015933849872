import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";

import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";

import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
     
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
     {/*} <Services data={landingPageData.Services} />*/}
      <Gallery data={landingPageData.Gallery} />
     {/* <Testimonials data={landingPageData.Testimonials} />*/}
    {/*  <Team data={landingPageData.Team} />*/}
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />

 
      <a
      target="_blank"
        href="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
        className="btn btn-wapp  btn-lg page-scroll"
     
      >
        <i className="fa fa-whatsapp"></i>
      </a>{" "}
    </div>

  );
};

export default App;
