import { ImageService } from "./image-service";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Link } from "react-router-dom";
import { Contact } from "./contact";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Services = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
    <div id="services" className="text-center">
       <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <Link  to="/">
            <img src="img/logosemfundo.png" alt="" height="55px" />
          </Link>{" "}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#header" className="page-scroll">
                Home
              </a>
            </li>
            
            <li>
              <Link to="/services"  className="page-scroll serv">
                Serviços
              </Link>
            </li>
            {/*<li>
              <Link to="/#testimonials" className="page-scroll">
                Clientes
              </Link>
  </li>*/}
            <li>
              <a href="/#about" className="page-scroll">
                Quem somos
              </a>
            </li>
            <li>
              <Link to="/blog" className="page-scroll">
                Blog
              </Link>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contato
              </a>
            </li>
            <li>
              <Link 
               to="/budget" className="page-scroll btn-orcamento">
                Orçamento
              </Link>
            </li>
            <li>
                    <Link
                    target="_blank" 
                    to="https://www.facebook.com/fortesol.ce">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link 
                    target="_blank"
                    to="https://www.instagram.com/fortesol.ce/">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link       
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol">
                      <i className="fa fa-whatsapp"></i>
                    </Link>
                  </li>
         
          </ul>
        </div>
      </div>
    </nav>
      <div className="container">
        <div className="section-title">
          <h2>Serviços</h2>
        </div>
        <div className="row">

        
           
              
          <div className="blog-items">
        <div className="section-title title-align">
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
              <h3>
                &nbsp; LAUDOS/PRONTUÁRIOS &nbsp;
              </h3>
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
            </div>
              <br />
            {props.data
              ? props.data.reports.map((d, i) => (
                <div
                key={`${d.title}-${i}`}
                className="col-sm-6 col-md-4 col-lg-3"
                >
                    <ImageService
                      title={d.title}
                      link={d.link}
                      smallImage={d.smallImage}
                    />
               
                  </div>
                ))
                : "Loading..."}
              
          </div>
        </div>
      
        <div className="row">
        
          
        
            <br />
            <br />
            <br />
          <div className="blog-items">
        <div className="section-title title-align">
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
              <h3>
                &nbsp; SERVIÇOS ELÉTRICOS &nbsp;
              </h3>
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
            </div>
              <br />
              <br />
            {props.data
              ? props.data.services.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-3"
                  >
                    <ImageService
                      title={d.title}
                      link={d.link}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
           
          <div className="blog-items">
        <div className="section-title title-align">
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
              <h3>
                &nbsp; SISTEMA FOTOVOLTAICO &nbsp;
              </h3>
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
            </div>
            {props.data
              ? props.data.sistema.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="img-test col-sm-6 col-md-4 col-lg-3"
                  >
                    <ImageService
                      title={d.title}
                      link={d.link}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div className="row orde1 orca-service">
              <div className="col-xs-12 col-md-6 text-pro">

                <h1>SOLICITE SEU ORÇAMENTO</h1>
                <br />
                <br />
                <Link
                  to="/budget"
                  className="btn-pulsando btn btn-custom btn-lg page-scroll"
                >
                  orçamento
                </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="img-pro" />
              </div>
            </div>
      </div>
      
      <Link
      target="_blank"
        to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
        className="btn btn-wapp  btn-lg page-scroll"
     
      >
        <i className="fa fa-whatsapp"></i>
      </Link>{" "}
    </div>





   <Contact />

    </>
  );
};

