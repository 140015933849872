import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const ParametrizacaoReles = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                  
                    




                    <div className="post-items">
                   <img src="img/portfolio/parametrizacao-reles.png" alt="" className="img-item" />
<div className="text-item">
                        <p><strong>O que é Parametrização de Relés de Proteção?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">A parametrização de relés de proteção é um serviço que visa garantir a segurança e a eficiência dos sistemas elétricos de uma empresa. Os relés de proteção são dispositivos que têm a função de proteger os equipamentos elétricos contra sobrecargas, curtos-circuitos e outras anomalias que possam comprometer o funcionamento do sistema.</p>
                        <p className="text-justify">A parametrização dos relés de proteção é um processo que consiste em ajustar os parâmetros de funcionamento dos relés de acordo com as características do sistema elétrico da empresa. Esses parâmetros incluem a corrente de operação, a corrente de sobrecarga, a corrente de curto-circuito, o tempo de atuação, entre outros.</p>
                        
                        <h1>Por que é importante fazer a Parametrização de Relés de Proteção?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A parametrização dos relés de proteção é um procedimento fundamental para garantir a segurança e a confiabilidade dos sistemas elétricos de uma empresa. Quando os relés não estão parametrizados corretamente, eles podem não atuar de forma adequada em situações de emergência, o que pode resultar em danos aos equipamentos elétricos e até mesmo em acidentes graves.</p>
                        <p className="text-justify">Além disso, a parametrização dos relés de proteção é uma exigência da legislação brasileira, que estabelece normas e padrões de segurança para os sistemas elétricos das empresas. Portanto, é fundamental que as empresas façam a parametrização dos relés de proteção regularmente para garantir o cumprimento da legislação e a segurança dos seus colaboradores e equipamentos.</p>

                        <h1>Como é feita a Parametrização de Relés de Proteção?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A parametrização dos relés de proteção é um procedimento técnico que deve ser realizado por profissionais qualificados e experientes. O processo de parametrização envolve a análise das características do sistema elétrico da empresa, a definição dos parâmetros de funcionamento dos relés e a programação dos relés de acordo com esses parâmetros.</p>
                        <p className="text-justify">Para garantir a eficácia da parametrização dos relés de proteção, é fundamental contar com o suporte de uma empresa especializada em serviços elétricos. A Fortesol Energia é uma empresa com mais de 10 anos de experiência no mercado, que oferece serviços de parametrização de relés de proteção com qualidade e segurança.</p>
                        
                        <h1> Por que escolher a Fortesol Energia?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A Fortesol Energia é uma empresa especializada em serviços elétricos que oferece soluções completas e personalizadas para os seus clientes. Com uma equipe de profissionais altamente qualificados e experientes, a Fortesol Energia está preparada para atender as necessidades de empresas de todos os portes e segmentos.</p>

                        
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>
            





            <Contact />

        </>
    );
};

