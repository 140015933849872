import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Aterramento = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">

                    <div className="post-items">
                   <img src="img/portfolio/aterramento.png" alt="" className="img-item" />
                        <div className="text-item">
                       
                        <p><strong>O que é um Laudo de Aterramento?</strong></p>
                        <br />
                        <p className="text-justify">O laudo de aterramento é um documento técnico que atesta a qualidade do sistema de aterramento de uma instalação elétrica. O aterramento é um sistema de proteção que tem como objetivo garantir a segurança das pessoas e dos equipamentos elétricos em caso de falhas na rede elétrica. O laudo de aterramento é fundamental para garantir a segurança das instalações elétricas e evitar acidentes.</p>
                        <p className="text-justify">O laudo de aterramento deve ser realizado por um profissional qualificado e deve seguir as normas técnicas vigentes. O laudo de aterramento é um documento obrigatório para empresas e indústrias que possuem instalações elétricas de média e alta tensão. O laudo de aterramento deve ser renovado periodicamente para garantir a segurança das instalações elétricas.</p>
                        <p className="text-justify">O Laudo de Aterramento registra o resultado da análise feita por Engenheiros Eletricistas, que inspecionam e descrevem as atuais condições dos aterramentos elétricos de um edifício, contemplando uma série de fatores, dentre os quais:</p>
                        <ul>
                            <li>A eficiência dos sistemas de aterramento;</li>
                            <li>
                                O estado de conservação de cada componente;
                            </li>
                            <li>
                                As medições ôhmicas dos aterramentos;
                            </li>
                            <li>
                                Relatórios fotográficos;
                            </li>
                            <li>
                                Os apontamento de não conformidade (se aplicável);
                            </li>
                            <li>
                                Sugestões para adequação das não conformidade (quando aplicável).
                            </li>
                            <br />
                            <p className="text-justify">Ao final, o Laudo de Aterramento possui orientações técnicas com planos de ação para um melhor desempenho e é emitido um atestado de CONFORMIDADE ou NÃO CONFORMIDADE do sistema de aterramento inspecionado.</p>
                        </ul>
                        <h1>O que é um Aterramento Elétrico?</h1>
                        <br />
                        <p className="text-justify">O aterramento elétrico é um sistema de proteção que tem como objetivo garantir a segurança das pessoas e dos equipamentos elétricos em caso de falhas na rede elétrica. O aterramento elétrico consiste na ligação de um condutor metálico à terra, que tem como função desviar a corrente elétrica para o solo em caso de falhas na rede elétrica.</p>
                        <p className="text-justify">O aterramento elétrico é fundamental para garantir a segurança das instalações elétricas e evitar acidentes. O aterramento elétrico deve ser dimensionado corretamente e deve seguir as normas técnicas vigentes. O aterramento elétrico é um sistema de proteção obrigatório para todas as instalações elétricas.</p>
                        <h1>Por que fazer um Laudo de Aterramento?</h1>
                        <br />
                        <p className="text-justify">O laudo de aterramento é um documento técnico que atesta a qualidade do sistema de aterramento de uma instalação elétrica. O laudo de aterramento é fundamental para garantir a segurança das instalações elétricas e evitar acidentes. O laudo de aterramento deve ser realizado por um profissional qualificado e deve seguir as normas técnicas vigentes.</p>
                        <p className="text-justify">O laudo de aterramento é um documento obrigatório para empresas e indústrias que possuem instalações elétricas de média e alta tensão. O laudo de aterramento deve ser renovado periodicamente para garantir a segurança das instalações elétricas. O laudo de aterramento é um documento importante para comprovar a qualidade do sistema de aterramento de uma instalação elétrica.</p>

                        <h1> Onde fazer um Laudo de Aterramento?</h1>
                        <br />
                        <br />
                        <p className="text-justify"> A Fortesol Energia é uma empresa especializada em laudos de aterramento. Contamos com uma equipe de engenheiros eletricistas qualificados e experientes, que realizam laudos de aterramento em conformidade com as normas técnicas vigentes. Realizamos laudos de aterramento para empresas e indústrias de todos os portes e segmentos.</p>
                        <p className="text-justify"> Entre em contato conosco e solicite um orçamento para a realização do laudo de aterramento da sua empresa. Estamos prontos para atender as suas necessidades e garantir a segurança das suas instalações elétricas.</p>
                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





          <Contact />
        </>
    );
};

