import React from "react";
import { Link } from "react-router-dom";
export const Features = (props) => {
  return (
    <div id="features" className="">
      
      <div className="container">
      <div className="row">
          
          <div className="col-xs-12 col-md-6">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1>DIGA-NOS O QUE VOCÊ <span>PRECISA!</span> <br /><br /> NÓS TE DAREMOS A<span> SOLUÇÃO!</span></h1>
              <h3><strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h3>
              <p>
Há mais de 30 anos trazendo soluções com qualidade e compromisso.</p>
              <a
                  href="#portfolio"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  nossos serviços
                </a>{" "}   
                <br />
                <br />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/features.png" className="img-feature" alt="" />{" "}
          </div>
        </div>


        
       {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
              </div>
              ))
              : "Loading..."}
            </div>*/}
      </div>
      <br />

    </div>
  );
};
