import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const SDAI = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                  
                    <div className="post-items">
                     <img src="img/portfolio/sdai.png" alt="" className="img-item" />

                    <div className="text-item">
                        <p><strong>O que é SDAI?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O Sistema de Detecção e Alarme de Incêndio (SDAI) é um conjunto de equipamentos que tem como objetivo detectar um princípio de incêndio em sua fase inicial, alertando as pessoas presentes no local e acionando os dispositivos de combate ao incêndio. O SDAI é um sistema de segurança fundamental para a proteção de vidas e do patrimônio, sendo obrigatório em diversos tipos de edificações, como prédios comerciais, residenciais, indústrias, entre outros.</p>
                        <p className="text-justify">O SDAI é composto por diversos equipamentos, como detectores de fumaça, detectores de temperatura, acionadores manuais, sirenes, centrais de alarme, entre outros. A escolha dos equipamentos e a instalação do sistema devem ser realizadas por profissionais qualificados, garantindo a eficiência e a confiabilidade do sistema.</p>
                        
                        <h1>Por que instalar um SDAI?</h1>
                        <br />                    
                        <br />
                        <p className="text-justify">A instalação de um Sistema de Detecção e Alarme de Incêndio é fundamental para garantir a segurança das pessoas e do patrimônio em caso de incêndio. O SDAI é capaz de detectar um princípio de incêndio em sua fase inicial, permitindo que as medidas de segurança sejam acionadas rapidamente e evitando danos maiores.</p>
                        <p className="text-justify">Além disso, o SDAI é obrigatório em diversos tipos de edificações, de acordo com a legislação vigente. A instalação do sistema deve ser realizada por profissionais qualificados, garantindo a eficiência e a confiabilidade do sistema.</p>
                        <p className="text-justify">Por fim, a instalação de um SDAI é um investimento na segurança e na proteção do patrimônio, garantindo a tranquilidade dos ocupantes do imóvel e evitando prejuízos em caso de incêndio.</p>

                        <h1>Quais são os tipos de SDAI?</h1>
                        <br />                    
                        <br />

                        <p className="text-justify">Existem diversos tipos de Sistemas de Detecção e Alarme de Incêndio, que podem ser classificados de acordo com a tecnologia utilizada, a forma de detecção do incêndio e a aplicação do sistema. Alguns dos principais tipos de SDAI são:</p>
                        <p className="text-justify">- SDAI convencional: é o tipo mais comum de sistema de detecção e alarme de incêndio, sendo utilizado em edificações de pequeno e médio porte. Nesse sistema, os detectores são divididos em zonas, permitindo identificar a área exata do incêndio.</p>
                        <p className="text-justify">- SDAI endereçável: é um sistema mais avançado, que permite identificar cada detector de forma individualizada. Esse tipo de sistema é indicado para edificações de grande porte, como shoppings, hospitais e indústrias.</p>
                        <p className="text-justify">- SDAI wireless: é um sistema sem fio, que utiliza a tecnologia de radiofrequência para a comunicação entre os equipamentos. Esse tipo de sistema é indicado para edificações onde a instalação de cabos é inviável.</p>
                        <p className="text-justify">- SDAI analógico: é um sistema que utiliza a tecnologia analógica para a detecção de incêndio. Esse tipo de sistema é mais preciso na identificação de incêndios, permitindo uma resposta mais rápida e eficiente.</p>

                        <h1>Como funciona um SDAI?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O Sistema de Detecção e Alarme de Incêndio funciona de forma integrada, utilizando diversos equipamentos para detectar um princípio de incêndio e acionar os dispositivos de segurança. O funcionamento do SDAI pode variar de acordo com o tipo de sistema e os equipamentos utilizados, mas de forma geral, o sistema funciona da seguinte maneira:</p>                    
                       <p className="text-justify">- Os detectores de fumaça e de temperatura identificam um princípio de incêndio em sua fase inicial, acionando a central de alarme.</p>
                          <p className="text-justify">- A central de alarme recebe o sinal dos detectores e aciona as sirenes e os acionadores manuais, alertando as pessoas presentes no local.</p>
                            <p className="text-justify">- Os ocupantes do imóvel são alertados sobre o incêndio e podem evacuar o local de forma segura.</p>
                                <p className="text-justify">- Os dispositivos de combate ao incêndio, como extintores e hidrantes, são acionados, permitindo a contenção do incêndio.</p>
                                    <p className="text-justify">- O sistema de detecção e alarme de incêndio é desativado após a contenção do incêndio, permitindo o retorno seguro dos ocupantes ao imóvel.</p>

                    <h1>Onde faço um orçamento para instalação de um SDAI?</h1>
                    <br />
                    <br />
                    <p className="text-justify">A Fortesol Energia é uma empresa especializada na instalação de Sistemas de Detecção e Alarme de Incêndio, oferecendo soluções completas e personalizadas para a proteção de vidas e do patrimônio. Nossa equipe de profissionais qualificados está preparada para realizar a instalação do SDAI de acordo com as normas técnicas e de segurança vigentes, garantindo a eficiência e a confiabilidade do sistema.</p>
                    <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





         <Contact />
        </>
    );
};

