import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Image } from "../components/image";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const FatorPotencia = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                    
       




                    <div className="post-items">
                    <img src="img/portfolio/fator-potencia.png" alt="" className="img-item" />

                     <div className="text-item">
                        <p><strong>O que é Projeto de Correção de Fator de Potência?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O fator de potência é uma medida que indica o quanto de energia está sendo desperdiçada em um sistema elétrico. Ele é calculado pela divisão da potência ativa pela potência aparente. O fator de potência ideal é 1, ou seja, toda a energia que entra no sistema é utilizada de forma eficiente. Quando o fator de potência é menor que 1, significa que parte da energia está sendo desperdiçada. Isso acontece porque a energia reativa, que é a energia que não é utilizada, acaba sobrecarregando o sistema elétrico e aumentando o consumo de energia.</p>
                        <p className="text-justify">O projeto de correção de fator de potência é uma solução para esse problema. Ele consiste em instalar equipamentos que corrigem o fator de potência, fazendo com que a energia reativa seja compensada e o sistema elétrico funcione de forma mais eficiente. Com o projeto de correção de fator de potência, é possível reduzir o consumo de energia, diminuir os custos com a conta de luz e aumentar a vida útil dos equipamentos elétricos.</p>
                        
                        <h1>Como funciona o Projeto de Correção de Fator de Potência?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O projeto de correção de fator de potência é feito por uma equipe de engenheiros especializados em sistemas elétricos. Eles analisam o sistema elétrico da empresa, identificam os pontos de desperdício de energia e projetam a instalação dos equipamentos necessários para corrigir o fator de potência. Os equipamentos mais comuns utilizados no projeto de correção de fator de potência são os bancos de capacitores, que são dispositivos que armazenam energia reativa e a liberam de forma controlada para compensar o excesso de energia reativa no sistema elétrico.</p>
                        <p className="text-justify">O projeto de correção de fator de potência é uma solução eficiente e econômica para reduzir o consumo de energia e aumentar a eficiência do sistema elétrico. Com ele, é possível economizar até 30% na conta de luz e aumentar a vida útil dos equipamentos elétricos. Além disso, o projeto de correção de fator de potência contribui para a preservação do meio ambiente, pois reduz o consumo de energia e a emissão de gases poluentes.</p>
                        <p className="text-justify">Se você deseja reduzir os custos com energia elétrica e aumentar a eficiência do seu sistema elétrico, entre em contato conosco e solicite um orçamento para o projeto de correção de fator de potência. Nossa equipe de engenheiros especializados está pronta para atender as suas necessidades e oferecer a melhor solução para o seu negócio.</p>

                        <h1>Por que escolher a FORTESOL?</h1>
                        <br />
                        <br />
                        <p className="text-justify">A FORTESOL é uma empresa especializada em soluções energéticas que oferece serviços de alta qualidade e tecnologia de ponta. Nossa equipe de engenheiros e técnicos é altamente qualificada e experiente, o que nos permite oferecer as melhores soluções para os nossos clientes. Além disso, contamos com equipamentos de última geração e parcerias com os melhores fornecedores do mercado, o que nos permite oferecer serviços de excelência e garantir a satisfação dos nossos clientes.</p>
                        <p className="text-justify">Ao escolher a FORTESOL para o seu projeto de correção de fator de potência, você terá a certeza de estar contando com uma empresa comprometida com a qualidade, a eficiência e a sustentabilidade. Nossos serviços são personalizados e adaptados às necessidades de cada cliente, garantindo a melhor solução para o seu negócio. Além disso, oferecemos um atendimento personalizado e um suporte técnico especializado, para garantir a satisfação e a tranquilidade dos nossos clientes.</p>
                        <p className="text-justify">Se você deseja reduzir os custos com energia elétrica e aumentar a eficiência do seu sistema elétrico, entre em contato conosco e solicite um orçamento para o projeto de correção de fator de potência. Estamos prontos para atender as suas necessidades e oferecer a melhor solução para o seu negócio. Conte com a FORTESOL para o seu projeto de correção de fator de potência e garanta a eficiência e a economia que o seu negócio precisa.</p>


                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





            <Contact />

        </>
    );
};

