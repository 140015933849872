import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const ResistividadeSolo = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
                  
      


                    <div className="post-items">
                <img src="img/portfolio/resistividade-solo.png" alt="" className="img-item" />

                     <div className="text-item">
                        <p><strong>o que é laudo resistividade do solo?</strong></p>
                        <br />
                        <br />
                        <p className="text-justify">O laudo de resistividade do solo é um documento técnico que tem como objetivo avaliar a resistividade do solo em um determinado local. A resistividade do solo é uma característica física do solo que indica a sua capacidade de resistir à passagem de corrente elétrica. A resistividade do solo é um parâmetro importante para a instalação de sistemas de aterramento e proteção contra descargas atmosféricas, pois influencia diretamente a eficiência desses sistemas.</p>

                        <br />
                        <br />
                       <h1>Para que serve o laudo de resistividade do solo?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O laudo de resistividade do solo é utilizado para dimensionar corretamente o sistema de aterramento e proteção contra descargas atmosféricas de uma instalação elétrica. Com base nos resultados do laudo, é possível determinar a quantidade de eletrodos de aterramento necessários, o espaçamento entre os eletrodos e a profundidade de instalação dos mesmos. Além disso, o laudo de resistividade do solo também é utilizado para verificar se o solo é adequado para a instalação de sistemas de aterramento e proteção contra descargas atmosféricas.</p>
                        <br />
                        <br />

                        <h1>Como é feito o laudo de resistividade do solo?</h1>
                        <br />
                        <br />
                        <p className="text-justify">O laudo de resistividade do solo é feito por meio de medições realizadas com um terrômetro, um equipamento que permite medir a resistividade do solo de forma rápida e precisa. As medições são feitas em vários pontos do terreno onde será instalado o sistema de aterramento e proteção contra descargas atmosféricas, de forma a obter uma amostra representativa da resistividade do solo naquele local. Com base nos resultados das medições, é elaborado o laudo de resistividade do solo, que contém as informações necessárias para dimensionar o sistema de aterramento e proteção contra descargas atmosféricas da instalação elétrica.</p>
                        <br />
                        <br />

                        <h1>Por que é importante fazer o laudo de resistividade do solo?</h1>
                        <br />
                        <br />

                        <p className="text-justify">O laudo de resistividade do solo é importante para garantir a eficiência e a segurança dos sistemas de aterramento e proteção contra descargas atmosféricas de uma instalação elétrica. Um sistema de aterramento mal dimensionado pode não ser capaz de dissipar corretamente as correntes elétricas de falta, o que pode resultar em danos aos equipamentos elétricos e riscos de choques elétricos. Além disso, um sistema de proteção contra descargas atmosféricas mal dimensionado pode não ser capaz de proteger adequadamente a instalação contra os efeitos das descargas atmosféricas, como danos aos equipamentos e incêndios.</p>
                        <br />
                        <br />

                        <p className="text-justify">Por isso, é fundamental realizar o laudo de resistividade do solo antes de instalar um sistema de aterramento e proteção contra descargas atmosféricas, para garantir a segurança e a eficiência da instalação elétrica.</p>
                        <br />
                        <br />

                        <h1>Quem pode fazer o laudo de resistividade do solo?</h1>
                        <br />
                        <br />

                        <p className="text-justify">O laudo de resistividade do solo deve ser feito por um profissional qualificado e experiente, que tenha conhecimento técnico e prático na área de sistemas de aterramento e proteção contra descargas atmosféricas. O profissional responsável pelo laudo deve possuir os equipamentos necessários para realizar as medições de resistividade do solo e interpretar os resultados obtidos. Além disso, é importante que o profissional siga as normas técnicas e regulamentações vigentes para a instalação de sistemas de aterramento e proteção contra descargas atmosféricas, para garantir a segurança e a eficiência da instalação elétrica.</p>
                        <br />
                        <br />

                        <h1>Onde fazer o laudo de resistividade do solo?</h1>
                        <br />
                        <br />

                        <p className="text-justify">A Fortesol Energia é uma empresa especializada na elaboração de laudos de resistividade do solo, sistemas de aterramento e proteção contra descargas atmosféricas. Contamos com uma equipe de profissionais qualificados e experientes, que estão preparados para atender as suas necessidades e garantir a segurança e a eficiência da sua instalação elétrica. Entre em contato conosco e solicite um orçamento para o laudo de resistividade do solo da sua instalação.</p>
                        <br />
                        <br />

                        





                        <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


<div className="portfolio-items ">
    <div className="carrosel">

        <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={sildPreView}
            navigation
            pagination={{ clickable: true }}

        >
            {props.data
                ? props.data.services.map((d, i) => (
                    <SwiperSlide key={i} >
                        <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}
                        />
                    </SwiperSlide>
                ))
                : "Loading..."
            }
        </Swiper>
    </div>
</div>
</div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>




<Contact  />

        </>
    );
};

