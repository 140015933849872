import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const SAVE = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />
                <div className="container">
            


                    <div className="post-items">
          <img src="img/portfolio/save.png" alt="" className="img-item" />
<div className="text-item">
                        <p><strong>O que é um sistema de alimentação vaícular e como funciona?</strong></p>
                        <br />
                        <br />
                       <p className="text-justify">O SAVE é um sistema de alimentação de veículos elétricos que permite a recarga de baterias de veículos elétricos, híbridos e plug-in híbridos. O sistema é composto por um carregador de veículos elétricos, um cabo de alimentação e um conector. O carregador de veículos elétricos é um dispositivo que converte a energia elétrica da rede em energia que pode ser armazenada nas baterias do veículo. O cabo de alimentação é o cabo que conecta o carregador de veículos elétricos ao veículo. O conector é o dispositivo que conecta o cabo de alimentação ao veículo.</p>
                       <p className="text-justify">O sistema de alimentação de veículos elétricos funciona da seguinte forma: o carregador de veículos elétricos é conectado à rede elétrica e ao veículo por meio do cabo de alimentação e do conector. O carregador de veículos elétricos converte a energia elétrica da rede em energia que pode ser armazenada nas baterias do veículo. O veículo é carregado com energia elétrica enquanto está conectado ao carregador de veículos elétricos. Quando o veículo está totalmente carregado, o carregador de veículos elétricos interrompe a carga.</p>
                       
                       <h1>Quais são os benefícios do sistema de alimentação de veículos elétricos?</h1>
                       <br />
                       <br />
                       <p className="text-justify">O sistema de alimentação de veículos elétricos oferece diversos benefícios, tanto para os usuários de veículos elétricos quanto para a sociedade como um todo. Alguns dos principais benefícios do sistema de alimentação de veículos elétricos são:</p>
                       <p className="text-justify">- Redução da emissão de gases de efeito estufa: os veículos elétricos são mais limpos do que os veículos movidos a combustíveis fósseis, pois não emitem gases de efeito estufa durante o funcionamento. Isso contribui para a redução do aquecimento global e da poluição do ar.</p>
                          <p className="text-justify">- Redução da dependência de combustíveis fósseis: os veículos elétricos não dependem de combustíveis fósseis, como gasolina e diesel, para funcionar. Isso reduz a dependência de fontes de energia não renováveis e contribui para a diversificação da matriz energética.</p>
                            <p className="text-justify">- Economia de custos: os veículos elétricos são mais eficientes do que os veículos movidos a combustíveis fósseis, o que resulta em economia de custos com combustível e manutenção. Além disso, o custo da eletricidade é geralmente mais baixo do que o custo dos combustíveis fósseis.</p>
                                <p className="text-justify">- Incentivo à geração de energia renovável: os veículos elétricos podem ser carregados com energia proveniente de fontes renováveis, como a energia solar e a energia eólica. Isso incentiva a geração de energia limpa e renovável.</p>
                                    <p className="text-justify">- Redução do ruído urbano: os veículos elétricos são mais silenciosos do que os veículos movidos a combustíveis fósseis, o que contribui para a redução do ruído urbano e melhora a qualidade de vida nas cidades.</p>
                                    <p className="text-justify">- Estímulo à inovação tecnológica: o desenvolvimento e a implantação de sistemas de alimentação de veículos elétricos estimulam a inovação tecnológica e a criação de novas oportunidades de negócios.</p>
                                        <p className="text-justify">- Redução da poluição do ar: os veículos elétricos não emitem poluentes atmosféricos, como monóxido de carbono, óxidos de nitrogênio e material particulado, durante o funcionamento. Isso contribui para a redução da poluição do ar e para a melhoria da qualidade do ar nas cidades.</p>
                                        <p className="text-justify">- Contribuição para a sustentabilidade: os veículos elétricos são mais sustentáveis do que os veículos movidos a combustíveis fósseis, pois utilizam energia limpa e renovável para funcionar. Isso contribui para a preservação do meio ambiente e para a promoção do desenvolvimento sustentável.</p>
                                            <p className="text-justify">- Redução da dependência de petróleo: os veículos elétricos não dependem do petróleo para funcionar, o que reduz a dependência de fontes de energia não renováveis e contribui para a diversificação da matriz energética.</p>
                                            <p className="text-justify">- Estímulo à mobilidade elétrica: o desenvolvimento e a implantação de sistemas de alimentação de veículos elétricos estimulam a mobilidade elé ́trica e a transição para um sistema de transporte mais sustentável e eficiente.</p>

                                            <h1>Como instalar um sistema de alimentação de veículos elétricos?</h1>
                                            <br />
                                            <br />

                                            <p className="text-justify">A instalação de um sistema de alimentação de veículos elétricos requer a realização de algumas etapas, que incluem a escolha do local de instalação, a instalação do carregador de veículos elétricos, a instalação do cabo de alimentação e do conector, e a realização de testes de funcionamento. A seguir, descrevemos as principais etapas para a instalação de um sistema de alimentação de veículos elétricos:</p>
                                            <p className="text-justify">- Escolha do local de instalação: o local de instalação do carregador de veículos elétricos deve ser seguro, acessível e adequado para a instalação do equipamento. O local deve ser protegido contra intempéries, como chuva, vento e sol, e deve estar próximo da rede elétrica.</p>
                                            <p className="text-justify">- Instalação do carregador de veículos elétricos: o carregador de veículos elétricos deve ser instalado em um local seguro, acessível e protegido contra intempéries. O carregador deve ser fixado em uma parede ou em um poste, de acordo com as instruções do fabricante.</p>
                                            <p className="text-justify">- Instalação do cabo de alimentação e do conector: o cabo de alimentação deve ser conectado ao carregador de veículos elétricos e ao veículo por meio do conector. O cabo de alimentação deve ser fixado em um suporte para evitar danos e acidentes.</p>
                                            <p className="text-justify">- Testes de funcionamento: após a instalação do sistema de alimentação de veículos elétricos, devem ser realizados testes de funcionamento para verificar se o equipamento está funcionando corretamente. Os testes devem ser realizados por um técnico especializado.</p>
                                            <p className="text-justify">- Manutenção preventiva: o sistema de alimentação de veículos elétricos deve ser submetido a manutenção preventiva regular para garantir o seu funcionamento adequado e prolonga a sua vida útil. A manutenção preventiva inclui a limpeza, a inspeção e a lubrificação do equipamento.</p>
                                            <p className="text-justify">- Treinamento dos usuários: os usuários do sistema de alimentação de veículos elétricos devem ser treinados para utilizar o equipamento de forma segura e eficiente. O treinamento deve incluir informações sobre o funcionamento do equipamento, os procedimentos de segurança e as precauções a serem observadas.</p>
                                            <p className="text-justify">- Monitoramento do sistema: o sistema de alimentação de veículos elétricos deve ser monitorado regularmente para verificar o seu desempenho e identificar eventuais problemas. O monitoramento pode ser realizado por meio de um sistema de gestão remota.</p>
                                            <p className="text-justify">- Atendimento a normas técnicas: a instalação e o funcionamento do sistema de alimentação de veículos elétricos devem atender às normas técnicas e regulamentações vigentes. É importante consultar as normas técnicas e regulamentações aplicáveis antes de instalar o equipamento.</p>
                                            <p className="text-justify">- Licenciamento ambiental: a instalação do sistema de alimentação de veículos elétricos pode estar sujeita a licenciamento ambiental, dependendo do porte e da localização do empreendimento. É importante consultar os órgãos ambientais competentes para obter as licenças necessárias.</p>

                                            <h1> Com a Fortesol você encontra as melhores soluções em sistemas de alimentação de veículos elétricos</h1>
                       
                                            <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>




<Contact />

        </>
    );
};

