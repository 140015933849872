import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Link } from "react-router-dom";
import { MdMessage } from "react-icons/md";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
      .then(
        (result) => {

          const modal = document.querySelector('.off');
          modal.click();
          clearState();
          setTimeout(() => {
            window.location.reload();
          }
            , 3000);
        },
        (error) => {
          const modal = document.querySelector('.error');
          modal.click();
          clearState();
          setTimeout(() => {
            window.location.reload();
          }
            , 3000);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h3>Nossa equipe está de prontidão</h3>
                <h2>entre em contato</h2>
                <p>
                Preencha o formulário abaixo para nos enviar um e-mail e entraremos em contato com você o mais breve possível.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  enviar mensagem
                </button>
              </form>
              <button className="off" data-toggle="modal" data-target="#ExemploModalCentralizado" />
              <div className="modal fade" id="ExemploModalCentralizado" tabindex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-body">
                    <div>

                      <h1><span></span>Mensagem enviada com sucesso!{' '}<span className="iconM"><MdMessage /></span></h1>
                      <p><span>fortesol</span> agradece a preferência!</p>
                    </div>
                  </div>
                </div>
              </div>
              <button className="error" data-toggle="modal" data-target="#ExemploModalCentralizado" />
              <div className="modal fade" id="ExemploModalCentralizado" tabindex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-body red">
                    <div>
                      <h1>ERRO, por favor, tente novamente!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de Contato</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                R. Luiza Teixeira de Araújo, 69 - São Francisco, Pentecoste - CE, 62640-000 
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                (85) 4141-4900
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                contato@fortesol.com.br
              </p>
            </div>
          </div>
              
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <Link
                    target="_blank" 
                    to="https://www.facebook.com/fortesol.ce">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link 
                    target="_blank"
                    to="https://www.instagram.com/fortesol.ce/">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link       
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol">
                      <i className="fa fa-whatsapp"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 FORTESOL ENERGIA. Design by{" "}
            <a  target="_blank" href="https://www.instagram.com/sampaio_technology" rel="nofollow">
              Sampaio Technology
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
