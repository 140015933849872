
import { Image } from "./image";
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";



export const Gallery = (props) => {

  const [sildPreView, setSildPreView] = React.useState(3);
  React.useEffect(() => {

    function handleResize() {
      if (window.innerWidth < 768) {
        setSildPreView(1);
      } else if (window.innerWidth < 1024) {
        setSildPreView(2);
      } else {
        setSildPreView(3);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="section-title col-sm-2  align-service">
              <h2>serviços</h2>
            </div>


            <div className="section-title title-align">
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
              <h3>
                &nbsp; LAUDOS & PRONTUÁRIOS &nbsp;
              </h3>
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
            </div>

            <div className="portfolio-items ">
              <div className="carrosel">

                <Swiper
                  autoplay={{ delay: 2500 }}
                  slidesPerView={sildPreView}
                  navigation
                  pagination={{ clickable: true }}

                >
                  {props.data
                    ? props.data.reports.map((d, i) => (
                      <SwiperSlide key={i} >
                        <Image
                          title={d.title}
                          link={d.link}
                          smallImage={d.smallImage}
                        />
                      </SwiperSlide>
                    ))
                    : "Loading..."
                  }
                </Swiper>
              </div>


            </div>
            <div className="section-title title-align">
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
              <h3>
                &nbsp; SERVIÇOS ELÉTRICOS &nbsp;
              </h3>
              <span style={{
                borderBottom: "2px solid #f8b600", color: "transparent",
              }}>------------------</span>
            </div>

            <div className="portfolio-items ">
              <div className="carrosel">

                <Swiper
                  autoplay={{ delay: 2500 }}
                  slidesPerView={sildPreView}
                  navigation
                  pagination={{ clickable: true }}

                >
                  {props.data
                    ? props.data.services.map((d, i) => (
                      <SwiperSlide key={i} >
                        <Image
                          title={d.title}
                          link={d.link}
                          smallImage={d.smallImage}
                        />
                      </SwiperSlide>
                    ))
                    : "Loading..."
                  }
                </Swiper>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 ctn">
            <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 text-pro">
                <h1>SOLICITE SEU ORÇAMENTO</h1>
                <br />
                <br />
                <Link
                  to="/budget"
                  className="btn btn-custom btn-lg page-scroll btn-pulsando"
                >
                  orçamento
                </Link>{" "}

              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="img-pro" />
              </div>
            </div>

            <div className="row orde2">

              <div className="section-title title-align">
                <span style={{
                  borderBottom: "2px solid #f8b600", color: "transparent",
                }}>------------------</span>
                <h3>
                  &nbsp; SISTEMA FOTOVOLTAICO &nbsp;
                </h3>
                <span style={{
                  borderBottom: "2px solid #f8b600", color: "transparent",
                }}>------------------</span>
              </div>

              <div className="portfolio-items ">
                <div className="carrosel-solar">

                  <Swiper

                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}

                  >
                    {props.data
                      ? props.data.sistema.map((d, i) => (
                        <SwiperSlide key={i} >
                          <Image
                            title={d.title}
                            link={d.link}
                            smallImage={d.smallImage}

                          />
                        </SwiperSlide>
                      ))
                      : "Loading..."
                    }
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
