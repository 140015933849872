import { Navigation } from "../components/navigation";
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "../components/image";
import { Link } from "react-router-dom";
import { Contact } from "../components/contact";


const initialState = {
    name: "",
    email: "",
    message: "",
};
export const AreasClassificadas = (props) => {


    const [sildPreView, setSildPreView] = React.useState(5);
    React.useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 768) {
                setSildPreView(1);
            } else if (window.innerWidth < 1024) {
                setSildPreView(2);
            } else {
                setSildPreView(5);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [{ name, email, message }, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

        emailjs
            .sendForm("OutlookMessage", "template_ogu5p5h", e.target, "pPh3oADNFGfeyKorv")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div id="post" className="text-center">
                <Navigation />


                <div className="container">


                    <div className="post-items">
                        <img src="img/portfolio/areas-classificadas.png" alt="" className="img-item" />
                        <div className="text-item">

                            <p><strong>O que é e para que serve?</strong></p>
                            <br />
                            <br />

                            <p className="text-justify"> O Laudo de Área Classificada é um documento técnico que atesta a conformidade de instalações elétricas em áreas classificadas com as normas vigentes. A área classificada é um ambiente onde há a presença de gases, vapores inflamáveis, poeiras combustíveis ou fibras inflamáveis em quantidade suficiente para gerar uma atmosfera explosiva. </p>
                            <p className="text-justify">A classificação de áreas é feita de acordo com a quantidade e a frequência de presença de substâncias inflamáveis. A classificação é feita em zonas, que são áreas onde a atmosfera explosiva pode estar presente. </p>
                            <p className="text-justify">O Laudo de Área Classificada é um documento obrigatório para empresas que possuem instalações elétricas em áreas classificadas. O laudo deve ser elaborado por um engenheiro eletricista ou engenheiro de segurança do trabalho, que deve avaliar as instalações elétricas e emitir um parecer técnico sobre a conformidade das instalações com as normas vigentes. </p>
                            <p className="text-justify">O Laudo de Área Classificada deve conter informações sobre a classificação da área, as medidas de segurança adotadas, as condições de operação das instalações elétricas, as recomendações para a prevenção de acidentes e as medidas corretivas necessárias para garantir a segurança das instalações. </p>

                            <h1>COMO É FEITA A AVALIAÇÃO PARA EMISSÃO DO LAUDO DE ÁREAS CLASSIFICADAS</h1>
                            <br />
                            <p className="text-justify">A avaliação para emissão do Laudo de Áreas Classificadas é feita por um engenheiro eletricista ou engenheiro de segurança do trabalho, que deve avaliar as instalações elétricas e emitir um parecer técnico sobre a conformidade das instalações com as normas vigentes. </p>
                            <p className="text-justify">A avaliação é feita com base nas normas técnicas vigentes, que estabelecem os critérios para a classificação de áreas, as medidas de segurança a serem adotadas e os procedimentos para a emissão do laudo. </p>
                            <p className="text-justify">Durante a avaliação, o engenheiro deve verificar se as instalações elétricas estão de acordo com as normas vigentes, se os equipamentos de proteção estão em bom estado de conservação, se as medidas de segurança estão sendo adotadas corretamente e se as condições de operação das instalações são seguras. </p>
                            <p className="text-justify">Ao final da avaliação, o engenheiro emite o Laudo de Áreas Classificadas, que atesta a conformidade das instalações elétricas com as normas vigentes e recomenda as medidas corretivas necessárias para garantir a segurança das instalações. </p>

                            <h1>QUAIS SÃO AS NORMAS TÉCNICAS QUE REGULAMENTAM AS ÁREAS CLASSIFICADAS</h1>
                            <br />
                            <p className="text-justify">Algumas das normas técnicas que regulamentam as áreas classificadas são as seguintes: </p>
                            <p className="text-justify">- NBR IEC 60079-10-1:2015 - Atmosferas explosivas - Parte 10-1: Classificação de áreas - Explosão de gás - Guia para a classificação de áreas onde são manipulados gases inflamáveis. </p>
                            <p className="text-justify">- NBR IEC 60079-10-2:2015 - Atmosferas explosivas - Parte 10-2: Classificação de áreas - Explosão de poeira - Guia para a classificação de áreas onde são manipuladas poeiras combustíveis. </p>
                            <p className="text-justify">- NBR IEC 60079-14:2015 - Atmosferas explosivas - Parte 14: Projeto, seleção e montagem de instalações elétricas em áreas classificadas. </p>
                            <p className="text-justify">- NBR IEC 60079-17:2015 - Atmosferas explosivas - Part 17: Inspeção e manutenção de instalações elétricas em áreas classificadas. </p>
                            <p className="text-justify">- NBR IEC 60079-19:2015 - Atmosferas explosivas - Parte 19: Equipamentos intrinsecamente seguros. </p>
                            <p className="text-justify">- NBR IEC 60079-20-1:2015 - Atmosferas explosivas - Parte 20-1: Material elétrico para atmosferas explosivas - Propriedades gerais. </p>
                            <p className="text-justify">- NBR IEC 60079-20-2:2015 - Atmosferas explosivas - Parte 20-2: Material elétrico para atmosferas explosivas - Segurança aumentada. </p>
                            <p className="text-justify">- NBR IEC 60079-20-7:2015 - Atmosferas explosivas - Parte 20-7: Material elétrico para atmosferas explosivas - Segurança intrínseca. </p>
                            <p className="text-justify">- NBR IEC 60079-25:2015 - Atmosferas explosivas - Parte 25: Sistemas de proteção intrínseca. </p>
                            <p className="text-justify">- NBR IEC 60079-26:2015 - Atmosferas explosivas - Parte 26: Equipamentos com proteção de invólucro. </p>
                            <p className="text-justify">- NBR IEC 60079-29-1:2015 - Atmosferas explosivas - Parte 29-1: Gás - Detecção de gás - Detecção de gás fixa. </p>
                            <p className="text-justify">- NBR IEC 60079-29-2:2015 - Atmosferas explosivas - Parte 29-2: Gás - Detecção de gás - Detecção de gás portátil. </p>
                            <p className="text-justify">- NBR IEC 60079-30-1:2015 - Atmosferas explosivas - Parte 30-1: Poeira - Detecção de poeira - Detecção de poeira fixa. </p>
                            <p className="text-justify">- NBR IEC 60079-30-2:2015 - Atmosferas explosivas - Parte 30-2: Poeira - Detecção de poeira - Detecção de poeira portátil. </p>
                            <p className="text-justify">- NBR IEC 60079-31:2015 - Atmosferas explosivas - Parte 31: Equipamentos a serem instalados em áreas classificadas. </p>
                            <p className="text-justify">- NBR IEC 60079-32-1:2015 - Atmosferas explosivas - Parte 32-1: Gás - Detecção de gás - Detecção de gás por ponto. </p>
                            <p className="text-justify">- NBR IEC 60079-32-2:2015 - Atmosferas explosivas - Parte 32-2: Gás - Detecção de gás - Detecção de gás por ponto. </p>

                            <h1>QUAIS SÃO AS VANTAGENS DE REALIZAR O LAUDO DE ÁREAS CLASSIFICADAS</h1>
                            <br />
                            <p className="text-justify">Realizar o Laudo de Áreas Classificadas traz diversas vantagens para as empresas, tais como: </p>
                            <p className="text-justify">- Garantir a segurança das instalações elétricas e dos trabalhadores que atuam nas áreas classificadas. </p>
                            <p className="text-justify">- Evitar acidentes e prejuízos materiais decorrentes de explosões e incêndios. </p>
                            <p className="text-justify">- Cumprir as normas técnicas vigentes e as exigências dos órgãos reguladores. </p>
                            <p className="text-justify">- Reduzir os riscos de acidentes de trabalho e doenças ocupacionais. </p>
                            <p className="text-justify">- Aumentar a produtividade e a eficiência das operações nas áreas classificadas. </p>
                            <p className="text-justify">- Melhorar a imagem da empresa perante os clientes, fornecedores e colaboradores. </p>
                            <p className="text-justify">- Reduzir os custos com manutenção, reparos e substituição de equipamentos. </p>
                            <p className="text-justify">- Aumentar a competitividade e a sustentabilidade do negócio. </p>

                            <h1>CONCLUSÃO</h1>
                            <br />
                            <p className="text-justify">O Laudo de Área Classificada é um documento técnico que atesta a conformidade de instalações elétricas em áreas classificadas com as normas vigentes. A área classificada é um ambiente onde há a presença de gases, vapores inflamáveis, poeiras combustíveis ou fibras inflamáveis em quantidade suficiente para gerar uma atmosfera explosiva. </p>

                            <h1> Onde posso obter o Laudo de Área Classificada?</h1>
                            <br />
                            <p className="text-justify">A Fortesol Energia é uma empresa especializada na elaboração de Laudo de Área Classificada. Contamos com uma equipe de engenheiros eletricistas e engenheiros de segurança do trabalho altamente qualificados e experientes, que estão preparados para atender as necessidades de sua empresa. </p>




                            <br />
                        <br />
                        <p><strong><i style={{color:'yellow'}} className="fa fa-warning"/>&nbsp;Contrate empresas registradas no CREA!</strong></p>
                        <br />
                        </div>
                        <div className="row orde1 ">
              <div className="col-xs-12 col-md-6 post-text-pro">
              <h1>Faça seu orçamento com a <strong className="forte">FORTE</strong><strong className="sol">SOL</strong></h1>
                        <p>Estamos prontos para atender as suas necessidades</p>
                        <Link
                            to="/budget"
                            className="btn-pulsando btn btn-custom btn-lg page-scroll"
                        >
                            orçamento
                        </Link>{" "}
              </div>
              <div className="col-xs-12 col-md-6">

                <img src="img/orcamento.png" alt="" className="post-img-pro" />
              </div>
            </div>
                    </div>

                    <br />
                    <br />
                    <br />

                    <div className="row">


                        <div className="portfolio-items ">
                            <div className="carrosel">

                                <Swiper
                                    autoplay={{ delay: 2500 }}
                                    slidesPerView={sildPreView}
                                    navigation
                                    pagination={{ clickable: true }}

                                >
                                    {props.data
                                        ? props.data.services.map((d, i) => (
                                            <SwiperSlide key={i} >
                                                <Image
                                                    title={d.title}
                                                    link={d.link}
                                                    smallImage={d.smallImage}
                                                />
                                            </SwiperSlide>
                                        ))
                                        : "Loading..."
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
                <Link
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
                    className="btn btn-wapp  btn-lg page-scroll"

                >
                    <i className="fa fa-whatsapp"></i>
                </Link>{" "}
            </div>





          <Contact />

        </>
    );
};

